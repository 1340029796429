.ta-invoices__title {
  padding: 20px 0 15px;
}

.ta-invoices__list {
  padding: 10px 0;
}

.ta-invoices__list .ta-accordion.inactive .ta-section-title {
  margin-top: 5px;
}

.ta-invoices__list .ta-simple-list-item.refund {
  border-color: #e4ebdd;
  background: #e4ebdd;
}

.ta-invoices__list .ta-simple-list-item.refund:hover {
  border-color: #505a5e;
}

.ta-invoices .ta-message {
  margin-top: 12px;
}

.ta-invoices .ta-invoices__list__loader {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: rgba(255,255,255,0.8);
  z-index: 2;
}
