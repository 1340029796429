.ta-apps__subscription-row {
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  display: flex;
  justify-content: space-between;
}

.ta-apps__subscription-row.bottom-offset {
  margin-bottom: 8px;
}

.ta-apps__subscription-row.clickable {
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  padding: 8px;
  cursor: pointer;
}

.ta-apps__subscription-row.active {
  border-radius: 3px;
  border: solid 1px #737b7e;
  padding: 8px;
}

.ta-apps__subscription-row.disabled {
  border-radius: 3px;
  border: dashed 1px #cbced0;
  padding: 8px;
  background-color: #f7f8f8;
  cursor: not-allowed;
}

.ta-apps__subscription-row.clickable:not(.disabled):hover {
  border-radius: 3px;
  border: solid 1px #c5c8ca;
  padding: 8px;
  background-color: #f0f1f2;
}

.ta-apps__subscription-row .ta-apps__subscription-row__title {
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.ta-apps__subscription-row .ta-apps__subscription-row__title span {
  font-weight: 300;
}

.ta-apps__subscription-row .ta-apps__subscription-row__text {
  opacity: 0.8;
  font-size: 12px;
  font-weight: normal;
}

.ta-apps__subscription-row .ta-apps__subscription-row__box {
  min-width: 72px;
  max-width: 200px;
  min-height: 50px;
  padding: 0 4px;
  border-radius: 6px;
  border: solid 1px #b6babd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  color: #505a5e;
  font-size: 12px;
  line-height: 1.33;
}

.ta-apps__subscription-row .ta-apps__subscription-row__box.big {
  min-width: 124px;
}

.ta-apps__subscription-row.active .ta-apps__subscription-row__box {
  background-color: #95c5a6;
  border-color: #95c5a6;
  color: #fff;
}

.ta-apps__subscription-row.disabled .ta-apps__subscription-row__box {
  background-color: #8ebec3;
  border-color: #8ebec3;
  color: #fff;
}

.ta-apps__subscription-row .ta-apps__subscription-row__box__value {
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
}

.ta-apps__subscription-row .ta-apps__subscription-row__box__label {
  font-weight: 300;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-apps__subscription-add__text {
  margin-bottom: 16px;
}

.ta-apps__subscription .ta-striped-title .ta-striped-title__label,
.ta-apps__subscription-add .ta-striped-title .ta-striped-title__label,
.ta-apps__subscription-cancelled .ta-striped-title .ta-striped-title__label {
  font-size: 10px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.5px;
  background: #fff;
}

.ta-apps__subscription-invoice-row {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #505a5e;
}

.ta-apps__subscription-invoice-row.big {
  font-size: 14px;
}

.ta-apps__subscription-invoice-row.with-divider {
  border-bottom: solid 1px #d4d6d7;
  padding-bottom: 8px;
}

.ta-apps__subscription-invoice-row .ta-apps__subscription-invoice-row__item {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.ta-apps__subscription-invoice-row .ta-apps__subscription-invoice-row__item>* {
  max-width: 48%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-apps__subscription-invoice-row .ta-apps__subscription-invoice-row__description {
  margin-top: 4px;
  font-weight: 300;
}

.ta-apps__subscription-invoice-row .ta-apps__subscription-invoice-row__description a {
  color: #505a5e;
  text-decoration: underline;
}

.ta-apps__subscription-form.ta-form,
.ta-apps__subscription-plans-form.ta-form {
  padding-bottom: 0;
}

.ta-apps__subscription-form .ta-form-control__addon {
  top: 14px;
}

.ta-apps__subscription-form__hint {
  margin-top: 4px;
  opacity: 0.5;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  line-height: 16px;
  color: #505a5e;
}

.ta-apps__subscription-form__terms {
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 6px;
  border: dashed 1px #cbced0;
  background-color: #f7f8f8;
  color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
}

.ta-apps__subscription .ta-btn-black-ghost,
.ta-apps__subscription-add .ta-btn-black-ghost,
.ta-apps__subscription-failed .ta-btn-black-ghost,
.ta-apps__subscription-cancelled .ta-btn-black-ghost {
  font-weight: bold;
}

.ta-apps__subscription__accordion .ta-form-error {
  line-height: normal;
}

.ta-apps__subscription .ta-apps__subscription__accordion,
.ta-apps__subscription-add .ta-apps__subscription__accordion {
  margin-top: 16px;
  margin-bottom: 16px;
  transition: background-color 0.2s;
  cursor: default;
  padding: 0 8px;
}

.ta-apps__subscription-cancelled .ta-apps__subscription__accordion {
  margin-top: 16px;
  transition: background-color 0.2s;
  cursor: default;
  padding: 0 8px;
}

.ta-apps__subscription .ta-apps__subscription__accordion.expand {
  background-color: #fff;
  height: auto;
}

.ta-apps__subscription-add .ta-apps__subscription__accordion.expand,
.ta-apps__subscription-cancelled .ta-apps__subscription__accordion.expand {
  background-color: #fff;
  height: auto;
  border-color: #cbced0;
  color: #505a5e;
}

.ta-apps__subscription .ta-apps__subscription__accordion.ta-btn-secondary .ta-accordion__title,
.ta-apps__subscription-add .ta-apps__subscription__accordion.ta-btn-primary .ta-accordion__title,
.ta-apps__subscription-cancelled .ta-apps__subscription__accordion.ta-btn-primary .ta-accordion__title {
  cursor: pointer;
  font-weight: bold;
}

.ta-apps__subscription .ta-apps__subscription__accordion .ta-accordion__title:after,
.ta-apps__subscription-add .ta-apps__subscription__accordion .ta-accordion__title:after,
.ta-apps__subscription-cancelled .ta-apps__subscription__accordion .ta-accordion__title:after {
  content: "";
}

.ta-apps__subscription .ta-apps__subscription__accordion .ta-accordion__body,
.ta-apps__subscription-add .ta-apps__subscription__accordion .ta-accordion__body,
.ta-apps__subscription-cancelled .ta-apps__subscription__accordion .ta-accordion__body {
  text-align: left;
}

.ta-apps__subscription__inner-accordion .ta-form-error {
  line-height: normal;
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion {
  margin-top: 16px;
  margin-bottom: 16px;
  transition: background-color 0.2s;
  cursor: default;
  padding: 0 8px;
}

.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion {
  margin-top: 16px;
  transition: background-color 0.2s;
  cursor: default;
  padding: 0 8px;
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion.open {
  background-color: #fff;
  height: auto;
}

.ta-apps__subscription-add .ta-apps__subscription__inner-accordion.open,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion.open {
  background-color: #fff;
  height: auto;
  border-color: #cbced0;
  color: #505a5e;
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion.ta-btn-secondary .ta-inner-accordion-title,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion.ta-btn-primary .ta-inner-accordion-title,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion.ta-btn-black-ghost .ta-inner-accordion-title,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion.ta-btn-primary .ta-inner-accordion-title,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion.ta-btn-black-ghost .ta-inner-accordion-title {
  cursor: pointer;
  font-weight: bold;
  margin-left: 0;
  margin-top: 0;
  width: 100%;
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion .ta-inner-accordion-title:after,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion .ta-inner-accordion-title:after,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion .ta-inner-accordion-title:after {
  content: "";
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion .ta-inner-accordion-body,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion .ta-inner-accordion-body,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion .ta-inner-accordion-body {
  text-align: left;
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion .ta-inner-accordion-body.open,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion .ta-inner-accordion-body.open,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion .ta-inner-accordion-body.open {
  overflow: visible;
}

.ta-apps__subscription .ta-apps__subscription__inner-accordion .ta-apps__subscription-form .ta-form-control__addon,
.ta-apps__subscription-add .ta-apps__subscription__inner-accordion .ta-apps__subscription-form .ta-form-control__addon,
.ta-apps__subscription-cancelled .ta-apps__subscription__inner-accordion .ta-apps__subscription-form .ta-form-control__addon {
  top: 5px;
}

.ta-apps__subscription-failed .ta-hover-popup {
  width: 100%;
}

.ta-apps__subscription-failed .ta-form {
  padding-bottom: 0;
}

.ta-apps__subscription-form__payment-method__button {
  margin-left: 25px;
  width: auto;
}

.ta-apps__subscription-billing__preview {
  border-bottom: solid 1px #d4d6d7;
  margin-bottom: 16px;
}

.ta-apps__subscription-billing__preview .ta-billing__preview__method .ta-simple-list-item {
  padding-left: 60px;
  border: solid 1px #505a5e;
  background-color: #f6f8fa;
  min-height: 60px;

}

.ta-apps__subscription-billing__preview .ta-billing__preview__method .ta-simple-list-item:last-child {
  margin-bottom: 0;
}

.ta-apps__subscription-form__payment-method .ta-form-group {
  padding-top: 0;
}

.ta-apps__subscription-form__payment-method .ta-radio {
  width: 100%;
}

.ta-apps__subscription-form__payment-method .ta-radio.active:before,
.ta-apps__subscription-form__payment-method .ta-radio:after {
  top: 50%;
  transform: translateY(-50%);
}

.ta-apps__subscription-form__payment-method .ta-simple-list-item.active,
.ta-apps__subscription-form__payment-method .ta-simple-list-item {
  padding-left: 60px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  margin-bottom: 0;
}

.ta-apps__subscription-form__payment-method .ta-simple-list-item.payment-method.paypal .ta-simple-list-item__title {
  margin: 10px 0;
}

.ta-apps__subscription-form__payment-method .ta-radio.active .ta-simple-list-item {
  border-color: #505a5e;
}

.ta-apps__subscription-form__payment-method .ta-radio:not(.active) .ta-simple-list-item {
  border-color: rgba(182, 186, 189, 0.5);
}

.ta-apps__subscription-form__payment-method .ta-simple-list-item__title-icon,
.ta-apps__subscription-form__payment-method .ta-simple-list-item:after,
.ta-apps__subscription-form__payment-method .ta-list-button {
  display: none;
}

.ta-apps__subscription-failed__divider {
  height: 1px;
  width: 100%;
  background-color: rgba(182, 186, 189, 0.5);
  margin-top: 16px;
  margin-bottom: 16px;
}

.ta-apps__subscription-form__billing-form__spinner {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}