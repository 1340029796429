.ta-resource-categories__title {
  padding: 20px 0 15px;
}

.ta-resource-categories .ta-title__content .ta-btn,
.ta-resource-categories__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-resource-categories .ta-title__content > :not(:first-child),
.ta-resource-categories__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-resource-categories .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-resource-categories .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-resource-categories .ta-fixed-content__body {
  padding-top: 15px;
  overflow: visible;
}

.ta-resource-categories .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 137px;
}

.ta-resource-categories .ta-simple-list-item {
  border: solid 1px rgba(182, 186, 189, 0.5);
}

.ta-resource-categories .ta-message.active {
  margin-top: 1px;
  margin-bottom: 16px;
}

.ta-resource-categories .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  height: 100%;
  overflow-x: hidden;
}

.ta-resource-categories__filters {
  margin-bottom: 3px;
}

.ta-form.ta-resource-categories__filter {
  position: relative;
}

.ta-form.ta-resource-categories__filter::after {
  content: '';
  position: absolute;
  top: 53px;
  left: -20px;
  height: 1px;
  width: 470px;
  background: #d4d6d7;
}

.ta-resource-categories__category-name {
  font-weight: 500;
  font-size: 15px;
  color: #505a5e;
  display: flex;
  align-items: center;
}

.ta-resource-categories__category-name .ta-global-label {
  margin-left: 8px;
}

.ta-resource-categories__category-name .ta-global-label i {
  margin-right: 2px;
}

.ta-resource-categories__active .ta-resoruce-category__type.dependency {
  background: #ecd007;
  color: #505a5e;
}

.ta-resource-categories__active .ta-resoruce-category__type.bookable {
  background: #769851;
  color: #fff;
}

.ta-resource-categories-to-delete--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.ta-resource-categories-to-delete--branch .ta-list-button {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  height: auto;
  line-height:normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-resource-categories-to-delete .ta-simple-list-item {
  padding-right: 10px;
}

.ta-resource-categories-to-delete .ta-message {
  margin-top: 0;
}

.ta-resource-categories-to-delete--branch__data {
  width: 70%;
}

.ta-resource-categories-to-delete--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-resource-categories-to-delete--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: #cb3b43;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-resource-categories-to-delete--branch__name {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  height: 18px;
}

.ta-resource-categories-to-delete--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-resource-categories-to-delete--item i {
  margin-right: 5px;
}

.ta-resource-categories__category .ta-simple-list-item {
  display: flex;
}

.ta-resource-categories__category .label {
  font-size: 10px;
  color: #505a5e;
  line-height: 1.2;
  padding: 1px 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-resoruce-category__type.label {
  margin-top: 1px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  padding: 1px 4px;
  line-height: 1.1;
  position: absolute;
  right: 10px;
}

.ta-resource-categories__category .label.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2); 
}

.ta-resource-categories__list .ta-fixed-content__header {
  padding: 20px 20px 0 20px;
}

.ta-resource-categories__list .ta-fixed-content__body {
  overflow-y: auto;
  padding: 5px 20px 20px;
  top: 85px;
}

.ta-resource-categories__list .ta-fixed-content:before {
  top: 276px;
  background: linear-gradient(to bottom, rgba(244,243,243, 1) 0%, rgba(244,243,243, 0) 100%);
}
