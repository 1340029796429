.ta-attributes__preview .ta-fixed-content__body {
  padding: 15px;
}

.ta-attributes__preview__summary {
  padding: 20px;
  background: #f4f3f3;
}

.ta-attributes__preview__title {
  padding: 20px 0 15px;
}

.ta-attributes__preview__title .ta-btn {
  height: 25px;
  width: 25px;
  line-height: 23px;
  margin-left: 4px;
}

.ta-attributes__preview__label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #505A5E;
  margin-bottom: 4px;
}

.ta-attributes__preview__value {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #505A5E;
  margin-bottom: 12px;
}

.ta-attributes__preview__value i {
  color: #769851;
}