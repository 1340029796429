.ta-globals-settings__form .ta-fixed-content__body {
  padding-top: 10px;
}

.ta-globals-settings__form .ta-simple-tabs__body {
  margin-bottom: 8px;
}

.ta-globals-settings .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-globals-settings .ta-section-title {
  margin-top: 0;
}

.ta-globals-settings__list strong {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
}

.ta-globals-settings__attributes_list {
  list-style: initial;
  margin-left: 12px;
}

.ta-globals-settings__attributes_list li {
  margin-bottom: 4px;
}

.ta-globals-settings__attributes_list li::marker {
  color: #b6babd;
  font-size: 12px;
}

.ta-globals-settings__section_title {
  font-weight: 700;
}

.ta-globals-attributes_container {
  margin-top: 4px;
}

.ta-globals-attributes_container li {
  margin: 0 0 4px 8px;
  font-size: 12px;
  line-height: 1.33;
  color: #60676a;
}

.ta-globals-attributes_container li::marker {
  font-size: 14px;
}

.ta-globals-attributes_container .ta-striped-title__label {
  color: rgba(28, 39, 43);
}

.ta-globals-attributes_container .ta-striped-title__label {
  background-color: #fff;
}

.ta-globals-attributes_container .ta-striped-title__label strong {
  color: inherit;
  font-weight: 700;
}

.ta-globals-settings__not_permitted_text {
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  margin-top: 4px;
}

.ta-globals-settings__title {
  padding: 20px 0 15px;
}

.ta-globals-settings .ta-title__content .ta-btn,
.ta-globals-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-globals-settings .ta-title__content > :not(:first-child),
.ta-globals-settings__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-globals-settings .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-globals-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}
