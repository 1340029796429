.ta-branches-preview .simple-list-item-resource .ta-btn-icon-only {
  width: 40px;
  height: 24px;
  line-height: 22px;
  margin-top: 2px;
  margin-left: 4px;
}

.ta-branches-preview .simple-list-item-resource .ta-btn-icon-only.success {
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border: 1px solid rgba(118, 152, 81, 0.15);
}

.ta-branches-preview .ta-btn-primary.ta-btn-icon-only.success {
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border: 1px solid rgba(118, 152, 81, 0.15);
  cursor: default;
}

.ta-branches-preview .ta-bordered-box {
  padding: 0;
  border: none;
}

.ta-branches-preview .simple-list-item-resource .ta-btn-icon-only.ta-btn-large {
  width: 24px;
  height: 70px;
  line-height: 68px;
  top: 0px;
  margin-top: -1px;
  margin-left: 2px;
}

.ta-branches-preview .simple-list-item-resource .ta-badge {
  margin-left: 4px;
}

.ta-branches-preview .simple-list-item-resource__not-confirm-wrapper {
  height: 100%;
}

/* NEW CODE */
.ta-allowance-plan__wrapper {
  padding-bottom: 10px;
  position: relative;
}

.ta-allowance-plan__wrapper:after {
  content: "";
  display: block;
  border-left: 2px dotted #b6babd;
  height: 22px;
  position: absolute;
  left: 5px;
  bottom: -5px;
}

.ta-allowance-plan__wrapper:last-child:after {
  display: none;
}

.ta-allowance-plan__day {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  position: relative;
  padding: 5px 0;
  padding-left: 18px;
}

.ta-allowance-plan__day:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #505a5e;
  background: #505a5e;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateZ(0);
}

.ta-allowance-plan__day.empty:before {
  background: #f4f3f3;
}

.ta-allowance-plan__time {
  font-size: 12px;
  font-weight: 300;
  color: #505a5e;
  padding: 4px 0;
  padding-left: 18px;
  position: relative;
}

.ta-allowance-plan__time:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #b6babd;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%) translateZ(0);
}

.ta-allowance-plan__time:after {
  content: "";
  display: block;
  border-left: 2px dotted #b6babd;
  height: 10px;
  position: absolute;
  left: 5px;
  bottom: 15px;
}

.ta-companies__preview__services .ta-icon-list .ta-dotted-timeline__item {
  padding: 1.5px 0 1px 18px;
}

.ta-companies__preview__services .ta-icon-list .ta-dotted-timeline__item.small {
  padding: 0 0 0 18px;
}

.ta-companies__preview__services .ta-icon-list-duration-pattern {
  padding-left: 4px;
}

.ta-companies__preview__groups .ta-icon-list .ta-dotted-timeline__item {
  padding: 1.5px 0 1px 18px;
}

.ta-companies__preview__groups .ta-icon-list .ta-dotted-timeline__item.small {
  padding: 0 0 0 18px;
}

.ta-companies__preview__groups .ta-icon-list-duration-pattern {
  padding-left: 4px;
}

.ta-icon-list__buttons.ta-confirmation__actions {
  top: 13px;
}

.ta-branch__preview__resources__list-title {
  height: 72px;
  cursor: pointer;
  color: #505a5e;
  padding: 4px 32px 4px 12px;
  display: flex;
  align-items: center;
}

.ta-branch__preview__resources__list-title::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 4px;
  border-radius: 3px 0 0 3px;
  background: #ebb65d;
}

.ta-branch__preview__resources__list-title.online::before {
  background: #769851;
}

.ta-branch__preview__resources .ta-accordion-list .ta-accordion .ta-accordion__title:after {
  right: 10px;
}

.ta-branch__preview__resources .ta-accordion-list .ta-accordion .ta-accordion__body .ta-section-title__icon {
  color: #b6babd;
}

.ta-branch__preview__resources .ta-accordion-list .ta-accordion .ta-accordion__body .ta-icon-list__title {
  font-weight: bold;
}

.ta-branch__preview__resources .ta-accordion-list .ta-accordion .ta-accordion-list__body > .ta-row > .ta-col.col-60 {
  padding-right: 8px;
}

.ta-branch__preview__resources .ta-accordion-list .ta-accordion .ta-accordion-list__body > .ta-row > .ta-col.col-40 {
  padding-left: 8px;
}

.ta-branch__preview__resources__list-title .ta-avatar {
  width: 48px;
  height: 48px;
  margin-bottom: 0;
  margin-right: 8px;
}

.ta-branch__preview__resources__list-title .ta-avatar img {
  width: 48px;
  height: 48px;
}

.ta-branch__preview__resources__list-title .ta-avatar .ta-avatar__initials {
  line-height: 48px;
}

.ta-branch__preview__resources__list-title-info i {
  width: 16px;
  margin-right: 4px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  color: #505a5e;
}

.ta-branch__preview__resources__list-title-name {
  margin-right: 8px;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.07;
  color: #505a5e;
  display: flex;
}

.ta-branch__preview__resources__list-title-tag {
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.09;
  margin-left: 8px;
}

.ta-branch__preview__resources__list-title-tag.owner {
  background-color: #ecd007;
  color: #505a5e;
}

.ta-branch__preview__resources__list-title-tag.admin {
  background-color: #cb3b43;
  color: #fff;
}

.ta-branch__preview__resources__list-title-tag.custom {
  background-color: #385f87;
  color: #fff;
}

.ta-branch__preview__resources__list-title-tag.locked {
  background-color: #cb3b43;
  color: #fff;
}

.ta-branch__preview__resources__list-title-id {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: #505a5e;
}

.ta-branch__preview__resources__list-title-email a {
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: #385f87;
}

.ta-branch__preview__resources__list-title-bookings {
  width: 64px;
  height: 64px;
  margin-left: auto;
  padding: 16px 0;
  border-radius: 3px;
  background-color: #385f87;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-align: center;
  color: #fff;
}

.ta-branch__preview__resources__list-title-bookings div {
  margin-top: 2px;
  font-size: 9px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.33;
  text-align: center;
  color: #fff;
}

.ta-branch__preview__resources__list-title-bookings.no-bookings {
  background-color: #fff;
  color: #505a5e;
  border: solid 1px #cbced0;
}

.ta-branch__preview__resources__list-title-bookings.no-bookings div {
  color: #505a5e;
}

.ta-branch__preview__resources__list-title-unlock-hover {
  margin-left: auto;
}

.ta-branch__preview__resources__list-title-unlock {
  height: 24px;
  padding: 4px 12px;
  border-radius: 3px;
  background-color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #fff;
}
