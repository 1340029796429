.ta-courses .ta-fixed-content__body {
  top: 62px;
}

.ta-courses__title {
  padding: 20px 0 15px;
}

.ta-courses .ta-title__content .ta-btn,
.ta-courses__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-courses .ta-title__content > :not(:first-child),
.ta-courses__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-courses .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-courses .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-courses .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 35px;
}

.ta-courses .ta-fixed-content__body {
  padding-bottom: 15px;
  padding-top: 5px;
}

.ta-courses .ta-form {
  padding-bottom: 0;
}

.ta-courses .ta-simple-list-item {
  border: 1px solid rgba(182, 186, 189, 0.5);
}

.ta-courses__list .ta-sortable-list {
  margin-top: 5px;
}

.ta-courses__form .ta-fixed-content__body {
  padding-top: 7px;
}

.ta-courses__preview .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-courses__list .ta-hover-popup {
  color: #505a5e;
}

.ta-popup__courses-help-box iframe {
  margin-top: 8px;
}

.ta-courses__course.updated {
  position: relative;
}

.ta-courses__course.updated:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  animation-duration: 2s;
  animation-name: course-updated-before;
  animation-iteration-count: 1;
}

@keyframes course-updated-before {
  0% {
    opacity: 1;
    background: rgba(244, 243, 243, 1);
  }

  100% {
    opacity: 0;
    background: rgba(244, 243, 243, 0);
  }
}

.ta-courses__course.updated:after {
  content: "\f021";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  opacity: 0;
  animation-duration: 2s;
  animation-name: course-updated-after;
  animation-iteration-count: 1;
}

@keyframes course-updated-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-courses__course.deleted {
  position: relative;
}

.ta-courses__course.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: course-deleted-before;
  animation-iteration-count: 1;
}

@keyframes course-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-courses__course.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: course-deleted-after;
  animation-iteration-count: 1;
}

@keyframes course-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-courses__course__is-stalled {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ecd007;
  width: 30px;
  border-radius: 0 2px 2px 0;
  border-top: 1px solid #ecd007;
  border-right: 1px solid #ecd007;
  border-bottom: 1px solid #ecd007;
  margin: -1px -1px -1px 0;
}

.ta-courses__course__is-stalled i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
}

.ta-courses__course.is-stalled .ta-courses__course__is-stalled {
  display: block;
}

.ta-courses .ta-simple-list-item.draggable .ta-courses__course__is-stalled {
  display: none;
}

.ta-courses__course>div {
  padding-bottom: 8px;
}

.ta-courses__category {
  position: relative;
  padding: 0 0 15px 0;
}

.ta-courses__category-type.global {
  color: #505a5e;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  border: 1px solid #b6babd;
  border-radius: 3px;
  font-size: 10px;
  transform: translateY(-2px) translateZ(0);
}

.ta-courses__category .ta-section-title {
  margin-top: 0;
}

.ta-courses__category .ta-section-title .ta-grab {
  cursor: grab;
}

.ta-courses__category.pending:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  opacity: 0.5;
  z-index: 99;
}

.ta-courses__category.pending:after {
  content: "\f110";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #505a5e;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  z-index: 100;
}

.ta-courses__category.deleted {
  position: relative;
}

.ta-courses__category.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  animation-duration: 2s;
  animation-name: course-category-deleted-before;
  animation-iteration-count: 1;
}

@keyframes course-category-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0.5);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-courses__category.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  animation-duration: 2s;
  animation-name: course-category-deleted-after;
  animation-iteration-count: 1;
}

@keyframes course-category-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-courses .ta-simple-list-item {
  margin-bottom: 0;
}

.ta-courses-mock-container .ta-simple-list-item {
  margin-bottom: 8px;
}

.ta-courses .ta-simple-list-item .ta-list-button {
  cursor: grab;
}

.ta-courses .ta-simple-list-item .ta-list-button:hover {
  background: #b6babd;
  color: #505a5e;
}

.ta-courses.categories-collapse .ta-courses__list {
  max-height: 50px;
  overflow: hidden;
}

.ta-courses.categories-collapse .ta-courses__category {
  padding: 0;
}

.ta-courses.categories-collapse .ta-dnd__container {
  margin-bottom: 15px;
}

.ta-courses.categories-collapse .ta-courses__list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.ta-courses.categories-collapse .ta-courses__list:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 99;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(244, 243, 243, 1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#f4f3f3', GradientType=0);
}

.ta-courses.categories-collapse .ta-list-button {
  display: none;
}

/* ***********************************************

FIX DND COMPONENT

Issue: https://github.com/atlassian/react-beautiful-dnd/issues/703
Remove left prop when fix is provide

*********************************************** */

.ta-courses__category__dnd-wrapper,
.ta-courses__course__dnd-wrapper {
  left: 15px !important;
}

.ta-courses__category__dnd-wrapper.is-dragging .ta-courses__category {
  opacity: 0.8;
}

.ta-courses__course__dnd-wrapper.is-dragging .ta-simple-list-item {
  opacity: 0.8;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
}

.ta-courses .ta-courses__category.has-buttons .ta-accordion .ta-accordion__title:after {
  right: 118px;
}

.ta-courses .ta-courses__category.no-delete-permissions .ta-accordion .ta-accordion__title:after {
  right: 90px;
}

.ta-courses .ta-courses__category.has-buttons.default .ta-accordion .ta-accordion__title:after,
.ta-courses .ta-courses__category.global .ta-accordion .ta-accordion__title:after {
  right: 62px;
}

.ta-courses .ta-courses__category.no-write-permissions .ta-accordion .ta-accordion__title:after {
  right: 33px;
}

.ta-hover-popup.ta-course__popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
}

.ta-course__popup .ta-hover-popup__content.left {
  width: 300px;
}

.ta-courses__category__delete-btn {
  opacity: 0.5;
}

.ta-courses .ta-accordion__body {
  position: relative;
  z-index: -1;
}

.ta-courses__category .ta-accordion__title {
  position: relative;
  z-index: -1;
}

.ta-courses__category .ta-accordion__title:hover {
  z-index: 1;
}

.ta-courses__category.global .ta-hover-popup__trigger {
  white-space: nowrap;
}

.ta-courses .ta-simple-list-item__title-icon .global-label {
  font-size: 10px;
  border: 1px solid #b6babd;
  color: #505a5e;
  line-height: 1.2;
  padding: 1px 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-courses .ta-simple-list-item__title-icon .global-label.edited, 
.ta-courses__category .global.edited {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2); 
}

.ta-courses__category .global.edited {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ta-courses__category .ta-default-category__text {
  margin-right: 5px;
}

.ta-courses .ta-simple-list-item__title-icon .global-label.deleted {
  border: solid 1px rgba(203, 59, 67, 0.4);
  background-color: rgba(203, 59, 67, 0.2);
}

.ta-courses .ta-simple-list-item__title-icon .ta-hover-popup__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.ta-courses .ta-simple-list-item__title-icon .ta-hover-popup__trigger i {
  margin-right: 2px;
}

.ta-courses-to-delete--branch {
  min-height: 80px;
  border-radius: 3px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  background-color: #ffffff;
  color: #5b6569;
  padding: 16px;
  margin: 5px 0;
  display: flex;
}

.ta-courses-to-delete .ta-simple-list-item {
  padding-right: 10px;
}

.ta-courses-to-delete .ta-message {
  margin-top: 0;
}

.ta-courses-to-delete--branch__data {
  width: 70%;
}

.ta-courses-to-delete--branch__buttons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-courses-to-delete--branch__buttons button {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: #cb3b43;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.ta-courses-to-delete--branch__name {
  font-size: 15px;
  font-weight: 500;
}

.ta-courses-to-delete--item {
  display: flex;
  margin: 5px 0;
  font-size: 12px;
}

.ta-courses-to-delete--item i {
  margin-right: 5px;
}

.ta-courses .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  height: 100%;
}

.ta-courses .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item .ta-fixed-content__header {
  padding: 20px;
}

.ta-courses .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item .ta-fixed-content__body {
  padding: 5px 20px 20px;
  top: 76px;
}

.ta-courses .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item .ta-fixed-content:before {
  top: 170px;
  left: 35px;
  right: 35px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}


.ta-courses__category .ta-accordion .ta-accordion__body {
  overflow: initial;
}