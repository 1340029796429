.ta-settings__security__title {
  padding: 20px 0 15px;
}

.ta-settings__security .ta-title__content .ta-btn,
.ta-settings__security__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-settings__security .ta-title__content > :not(:first-child),
.ta-settings__security__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-settings__security .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-settings__security .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-settings__form.ta-security-settings__form .ta-fixed-content__body {
  padding-top: 0;
}

.ta-settings__form.ta-security-settings__form .left-offset {
  margin-left: 0;
}

.ta-security-settings__form .ta-form-text.thin-text {
  font-size: 12px;
}

.ta-settings__security .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-settings__form.ta-security-settings__form .ta-simple-tabs__body{
  margin-bottom: 8px;
}