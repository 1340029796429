.ta-availability-timelines .ta-availability-timelines__header {
    margin-left: 28px;
    margin-bottom: 3px;
    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    color: #b6babd;
    height: 10px;
    position: relative;
}

.ta-availability-timelines .ta-availability-timelines__header.tall {
    height: 20px;
}

.ta-availability-timelines .ta-availability-timelines__header__hour {
    position: absolute;
    width: 16px;
}

.ta-availability-timelines .ta-availability-timelines__header__hour:not(:first-child):not(.left-shift) {
    transform: translateX(-50%);
    text-align: center;
}

.ta-availability-timelines .ta-availability-timelines__header__hour.left-shift {
    transform: translateX(-100%);
    text-align: right;
}

.ta-availability-timelines .ta-availability-timelines__grid {
    position: relative;
}

.ta-availability-timelines .ta-availability-timelines__grid__background {
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: 28px;
    right: 0;
}

.ta-availability-timelines .ta-availability-timelines__grid__background-line {
    height: 100%;
    width: 1px;
    background-color: #e5e6e7;
    position: absolute;
    top: 0;
}

.ta-availability-timelines .ta-availability-timelines__grid-day {
    margin-bottom: 32px;
    height: 16px;
    position: relative;
}

.ta-availability-timelines .ta-availability-timelines__grid-day.big {
    height: 44px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day:first-child {
    margin-top: 22px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day:last-child {
    margin-bottom: 20px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-label {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: #505a5e;
    text-transform: capitalize;
    position: absolute;
    top: -4px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day.big .ta-availability-timelines__grid-day-label {
    top: 50%;
    transform: translateY(-50%);
}

.ta-availability-timelines .ta-availability-timelines__grid-day-intervals {
    margin-left: 28px;
    position: relative;
    height: 16px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day.big .ta-availability-timelines__grid-day-intervals {
    height: 44px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-unavailable-interval {
    background: rgba(80, 90, 94, 0.12) url('../../Assets/images/patterns/pattern-backslash.png') 0 0 repeat;
    background-size: 10px;
    height: 8px;
    position: absolute;
}

.ta-availability-timelines .ta-availability-timelines__grid-day.big .ta-availability-timelines__grid-day-unavailable-interval {
    height: 44px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval {
    height: 8px;
    position: absolute;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval::before {
    content: ' ';
    width: 1px;
    height: 12px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval::after {
    content: ' ';
    width: 1px;
    height: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    pointer-events: none;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.working {
    background-color: #b6babd;
    top: 0;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.working::before,
.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.working::after {
    background-color: #b6babd;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.booking {
    background-color: #769851;
    bottom: 0;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.booking::before,
.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.booking::after {
    background-color: #769851;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval.pointer {
    cursor: pointer;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval .ta-hover-popup {
    width: 100%;
    top: -4px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval__hover-trigger {
    height: 8px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval__hover-trigger::before {
    content: ' ';
    width: 1px;
    height: 12px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}

.ta-availability-timelines .ta-availability-timelines__grid-day-available-interval__hover-trigger::after {
    content: ' ';
    width: 1px;
    height: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
}

.ta-availability-timelines .ta-availability-timelines__grid-day-unavailable-label {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    color: #505a5e;
    padding: 2px 4px;
    border-radius: 4px;
    border: solid 1px #cbced0;
    background-color: #fff;
    position: absolute;
    left: calc(50% + 14px);
    transform: translateX(-50%);
    top: -5px;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-unavailable-label.working {
    left: 50%;
    top: 0;
}

.ta-availability-timelines .ta-availability-timelines__grid-day-unavailable-label.booking {
    left: 50%;
    top: 100%;
    transform: translate(-50%, -100%);
}

.ta-availability-timelines__grid-day-available-interval-time {
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: #505a5e;
    position: absolute;
}

.ta-availability-timelines__grid-day-available-interval-time.begin {
    left: 2px;
    top: calc(-100% - 2px);
}

.ta-availability-timelines__grid-day-available-interval-time.end {
    right: 2px;
    bottom: calc(-100% - 3px);
}

.ta-availability-timelines__indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}
