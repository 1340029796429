.ta-branch__preview__details .ta-badge {
  display: inline-block;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  white-space: nowrap;
} 

.ta-branch__preview__details .ta-row {
  table-layout: fixed;
}