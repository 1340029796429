/* Cols */

.ta-user-group__title {
  padding: 20px 0 15px;
}

.ta-user-groups .ta-title__content .ta-btn,
.ta-user-groups__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-user-groups .ta-title__content > :not(:first-child),
.ta-user-groups__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-user-groups .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-user-groups .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-user-groups .ta-row-labels .col-39 {
  width: 39%
}

.ta-user-groups .ta-row-labels .col-8 {
  width: 8%
}

.ta-user-groups .ta-col {
  vertical-align: middle;
  padding: 0;
}

.ta-user-groups .col-5 {
  vertical-align: bottom;
}

.ta-user-groups .col-7 {
  width: 7%;
}

.ta-user-groups .col-9 {
  width: 9%;
}

.ta-user-groups .col-13 {
  width: 13.5%;
}

.ta-user-groups .col-11 {
  width: 11.5%;
}

.ta-user-groups .ta-col-small {
  width: 131px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #505a5e;
}

.ta-user-groups .ta-col-big {
  width: 285px;
}

/* Rows */

.ta-user-groups .ta-user-groups-list .ta-row {
  margin: 0;
}

/* Page Title */

.ta-user-groups .ta-title-content__input-search {
  width: 186px;
  margin-bottom: 1px;
}

/* list items cols */

.ta-user-groups .ta-simple-list-item .ta-col, .ta-user-groups .ta-simple-list-item .ta-col {
  border-right: 1px solid rgba(80, 90, 94, 0.25);
  vertical-align: middle;
}

.ta-user-groups .ta-simple-list-item .ta-col:last-child {
  border-right: none;
}

/* Row Labels */

.ta-user-groups .ta-row-labels .ta-col .ta-section-title__label {
  font-size: 10px;
  font-weight: bold;
  color: #505a5e;
  text-transform: uppercase;
}

.ta-user-groups .ta-row-labels .col-39 .ta-section-title {
  padding-left: 0;
}

.ta-user-groups .ta-row-labels .col-10 .ta-section-title {
  padding-left: 39px;
}

.ta-user-groups .ta-section-title, .ta-user-groups .col-7 .ta-section-title {
  padding-left: 6px;
}

.ta-user-groups .col-7 .ta-section-title {
  padding-left: 28px;
}

/* List Items */

.ta-user-groups .ta-simple-list-item {
  margin: 0;
  padding: 0;
}

.ta-user-groups .ta-simple-list-item__title {
  margin-left: 8px;
  opacity: 1;
}

.ta-user-groups .ta-simple-list-item .ta-simple-list-item__title-text {
  height: 16px;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #505a5e;
  opacity: 1;
}

.ta-user-groups .ta-simple-list-item__content {
  opacity: 1;
}

.ta-user-groups .ta-simple-list-item .ta-list-inline {
  margin-left: 8px;
}

.ta-user-groups .ta-simple-list-item .ta-list-inline li {
  line-height: 1.6;
  font-size: 12px;
  font-weight: 300;
}

.ta-user-groups .ta-simple-list-item .ta-list-inline__item {
  padding-right: 4px;
}

/* Buttons */

.ta-user-groups .ta-user-groups-list-btn {
  width: 24px;
  height: 100%;
  padding: 24px 4px;
  margin: 4px;
  line-height: 0;
}

.ta-user-groups .ta-user-groups-list-btn>* {
  font-size: 14px;
}

.ta-user-groups .ta-user-groups-list-btn:first-child {
  margin-right: 0;
}

.ta-user-groups .ta-btn.ta-btn-secondary:hover {
  background: #9b9ea1;
}

/* plan boxes */

.ta-user-groups .ta-user-groups-plan {
  display: inline-block;
  height: 16px;
  border-radius: 3.3px;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.09;
  color: #ffffff;
  padding: 4px;
}

.ta-user-groups .ta-user-groups-box-enterprise {
  background-color: #cb3b43;
}

/*  */

.ta-user-groups .ta-col.col-50 .ta-form-group {
  padding: 5px;
}
