.ta-customers-settings__title {
  padding: 20px 0 15px;
}

.ta-customers-settings .ta-title__content .ta-btn,
.ta-customers-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-customers-settings .ta-title__content > :not(:first-child),
.ta-customers-settings__preview .ta-title__content > :not(:first-child) {
  margin-left: 4px;
}

.ta-customers-settings .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-customers-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-settings .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-settings__form .ta-settings__btn-delete {
  cursor: pointer;
  width: 24px;
  height: 40px;
  line-height: 38px;
  background: #b6babd;
  color: #505a5e;
  border-radius: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
}

.ta-settings__form .ta-settings__btn-add {
  background: #fff;
  border-radius: 6px;
  border: solid 1px #505a5e;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #505a5e;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
}

.ta-settings__form .ta-settings__btn-add.top-offset {
  margin-top: 8px;
}

.ta-settings__form .ta-settings__btn-add i {
  font-size: 14px;
}

.ta-settings__form .ta-form-text.thin-text {
  font-size: 12px;
}

.ta-settings__form .ta-settings-customers__eugdpr__rule-container {
  position: relative;
}

.ta-settings__form .ta-settings-customers__eugdpr__rule-customer-type.has-delete {
  margin-right: 30px;
}

.ta-settings__form .ta-settings-customers__eugdpr__rule-customer-tag.has-delete {
  margin-right: 30px;
}

.ta-settings__form .ta-hover-popup {
  display: block;
}

/* .ta-settings__form .ta-bordered-box .ta-row:last-child {
  margin-bottom: 8px;
} */

.ta-settings__form .ta-settings__form--inner-box {
  margin-bottom: 0;
}

.ta-settings__form .ta-settings__form--outer-box {
  margin-bottom: 0;
}

.ta-settings__form .ta-row__no-margin {
  margin-bottom: 0;
}

.ta-customers-settings .ta-section-title {
  margin-bottom: 4px;
}

.ta-list-item__participant:before {
  color: #b6babd;
}
