.ta-popup {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.ta-popup.active {
  opacity: 1;
  transition: filter 0s;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
  visibility: visible;
}

.ta-popup__content {
  position: absolute;
  transform: translateX(10px);
  background: #fff;
  border-radius: 6px;
  width: 350px;
  color: #505a5e;
  font-size: 13px;
  font-weight: 400;
  transition: all 0s;
  line-height: 16px;
}

/* .ta-popup__content.top-left {
  transform: translate(-90%, -90%);
} */

.ta-popup__content.left {
  transform: translate(10px, -50%);
}

.ta-popup__content.right {
  transform: translate(10px, -50%);
}

.ta-popup__content-inner {
  overflow: auto;
  padding: 15px 15px 5px 15px;
  max-height: 400px;
  position: relative;
}

.ta-popup__content-inner:before {
  content: "";
  position: fixed;
  top: 0;
  left: 5px;
  right: 5px;
  z-index: 10;
  height: 10px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#ffffff', startColorstr='#00ffffff', GradientType=0);
}

.ta-popup__content-inner:after {
  content: "";
  position: fixed;
  bottom: 0;
  left: 5px;
  right: 5px;
  z-index: 10;
  height: 10px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#ffffff', startColorstr='#00ffffff', GradientType=0);
}

.ta-popup__content:after {
  content: "";
  border: 8px solid transparent;
  position: absolute;
}

.ta-popup__content.right:after {
  border-right-color: #fff;
  top: 50%;
  right: 100%;
  transform: translateY(-50%) translateZ(0);
}

.ta-popup__content.left:after {
  border-left-color: #fff;
  top: 50%;
  left: 100%;
  transform: translateY(-50%) translateZ(0);
}

.ta-popup__content.top:after {
  border-top-color: #fff;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.ta-popup__content.top-right:after {
  border-top-color: #fff;
  top: 100%;
  left: calc(100% - 20px);
  transform: translateX(-50%);
}

.ta-popup__content.top-left:after {
  border-left-color: #fff;
  top: calc(100% - 32px);
  left: 100%;
}

.ta-popup__content.bottom:after {
  border-bottom-color: #fff;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.ta-popup__trigger {
  display: inline-block;
}

.ta-popup__trigger.block {
  display: block;
}

.ta-popup__title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 20px;
}

.ta-popup__text {
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
}

.ta-popup__text,
.ta-popup__text p {
  margin-bottom: 10px;
}

.ta-popup__text p:last-child {
  margin-bottom: 0;
}

.ta-popup__buttons {
  margin-bottom: 10px;
}

.ta-popup__buttons .ta-btn {
  margin-right: 10px;
}

.ta-popup__buttons.block .ta-btn {
  margin: 0;
}

.ta-popup__buttons .ta-btn.pull-right {
  margin-right: 0;
  margin-left: 10px;
}

/* Help box css override */

.ta-popup__content.ta-help-box .ta-popup__content-inner {
  padding: 0;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ta-popup__content.ta-help-box .ta-popup__content-inner::-webkit-scrollbar {
  display: none;
}

.ta-popup__content.ta-help-box .ta-popup__content-inner:before,
.ta-popup__content.ta-help-box .ta-popup__content-inner:after {
  display: none;
}

.ta-popup__content.ta-help-box .ta-popup__content-inner .ta-message {
  margin-bottom: 0;
}

.ta-popup__content.ta-help-box.bottom:after {
  border-bottom-color: #FCF4E7;
}

/* Right Sidebar css override */

.ta-popup__content.ta-right-sidebar-help-box {
  transform: translateX(-140px);
}

.ta-popup__content.ta-right-sidebar-help-box.bottom:after {
  left: auto;
  right: 10px;
}

/* OTHER */

.ta-popup .ta-form {
  padding-bottom: 0;
}

.ta-popup .ta-form .ta-popup__buttons {
  margin-top: 16px;
}

.ta-popup .ta-single-select .Select-menu-outer {
  max-height: 130px;
}

.ta-popup .ta-single-select .Select-menu {
  max-height: 118px;
}

.ta-popup .ta-section-title__label {
  height: auto;
  white-space: normal;
  line-height: 16px;
  padding: 4px 0;
}

.ta-popup__booking-edit__wrapper.ta-popup__content.bottom,
.ta-popup__booking-edit__wrapper.ta-popup__content.top,
.ta-popup__booking-delete__wrapper.ta-popup__content.bottom,
.ta-popup__booking-delete__wrapper.ta-popup__content.top {
  width: 400px;
  transform: translateX(-15px);
  transition-delay: 0.3s;
}

.ta-popup__content.ta-participants-export-btn {
  max-width: 166px;
}

.ta-popup__email-change-cancel .ta-popup__email-change-cancel__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

/* Statistics box css override */
.ta-popup__content.ta-statistics-box {
  width: 216px;
}

.ta-popup__content.ta-statistics-box .ta-popup__content-inner {
  padding: 8px;
  height: 264px;
}

.ta-popup__content.ta-statistics-box.small .ta-popup__content-inner {
  height: unset;
}

.ta-popup__content.ta-statistics-box .ta-popup__brach-statistics:before {
  content: "";
  position: fixed;
  top: 0;
  left: 5px;
  right: 5px;
  z-index: 10;
  height: 10px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#ffffff', startColorstr='#00ffffff', GradientType=0);
}

.ta-popup__content.ta-statistics-box .ta-popup__brach-statistics:after {
  content: "";
  position: fixed;
  bottom: 0;
  left: 5px;
  right: 5px;
  z-index: 10;
  height: 10px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#ffffff', startColorstr='#00ffffff', GradientType=0);
}

.ta-popup__content.ta-statistics-box.small .ta-popup__brach-statistics:after {
  display: none;
}

.ta-popup__content.ta-statistics-box .ta-popup__content-inner .ta-tags__item {
  cursor: pointer;
}

.ta-popup__content.ta-statistics-box.small .ta-form-group {
  margin-bottom: 0px;
}

.ta-popup__content.ta-statistics-box .ta-form-group .ta-form-control__btn-clear {
  top: 4px;
}

.ta-popup__content.ta-statistics-box .ta-form {
  padding-bottom: 0px;
}

.ta-popup__content.ta-statistics-box .ta-table-row {
  border: 1px solid #e5e6e7;
}

.ta-popup__content.ta-statistics-box .ta-table-row:hover {
  border: 1px solid #505a5e;
}

.ta-popup__content.ta-statistics-box .ta-popup__content-inner:before,
.ta-popup__content.ta-statistics-box .ta-popup__content-inner:after {
  display: none;
}

.ta-popup__content.ta-statistics-box .ta-popup__content-inner .ta-message {
  margin-bottom: 0;
}

.ta-popup__content.ta-statistics-box.bottom:after {
  border-bottom-color: #FCF4E7;
}

/* Right Sidebar css override */

.ta-popup__content.ta-right-sidebar-help-box {
  transform: translateX(-140px);
}

.ta-popup__content.ta-right-sidebar-help-box.bottom:after {
  left: auto;
  right: 10px;
}

.ta-popup__email-change-cancel .ta-popup__email-change-cancel__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-popup__email-change-cancel .ta-form {
  padding-bottom: 0px;
}

.ta-popup__account-unlink .ta-popup__title i {
  margin-right: 8px;
  color: #b6babd;
}

.ta-popup__account-unlink .ta-popup__title {
  border-bottom: 1px solid #d4d6d7;
  padding-bottom: 5px;
}

.ta-service-import-popup .ta-popup__content-inner,
.ta-group-import-popup .ta-popup__content-inner {
  padding: 10px;
}

.ta-popup__services-create-type-select__btn {
  position: relative;
  border: 1px solid #cbced0;
  padding: 8px;
  padding-left: 32px;
  border-radius: 3px;
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: #505a5e;
}

.ta-popup__services-create-type-select__btn:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 1px solid rgba(80, 90, 94, 0.6);
  transform: translateY(-50%);
}

.ta-popup__services-create-type-select__btn:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-popup__services-create-type-select__btn.last {
  margin-bottom: 0;
}

.ta-popup__services-create-type-select__btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-popup__services-create-type-select__btn__title {
  font-weight: 500;
  display: block;
}

.ta-popup__content.service-create-type-select {
  width: 300px;
}

.ta-popup__users-delete .ta-form-text {
  word-break: break-word;
}

.ta-popup__branch-delete .ta-popup__branch-delete__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-popup__text_ics_tooltip {
  word-break: break-word;
}