.ta-services__form .ta-buffer-times__box {
  bottom: auto;
  top: 27px;
}

.ta-services__form .ta-message.active {
  margin: 8px 0 0 0;
}

.ta-services__form .ta-simple-tabs__body {
  margin-bottom: 8px;
}

.ta-services__form .ta-services-duration-tooltip {
  float: right;
}

.ta-services__form__times .ta-simple-tabs__body {
  margin-bottom: 0;
}

.ta-services__form__times .ta-message {
  margin-bottom: 0;
}

.ta-services__form__times__plan {
  position: relative;
}

.ta-services__form__times__plan.disabled:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 6;
  background: rgba(255, 255, 255, 0.7);
}

.ta-services__form .ta-services__form__reminder-container,
.ta-services__form .ta-services__form__recipient-container {
  position: relative;
}

.ta-services__form .ta-services__form__reminder.has-delete,
.ta-services__form .ta-services__form__recipient.has-delete {
  margin-right: 30px;
}

.ta-services__form .ta-services__form__btn-delete {
  cursor: pointer;
  width: 24px;
  height: 40px;
  line-height: 40px;
  background: #b6babd;
  color: #505a5e;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
}

.ta-services__form .ta-services__form__btn-add {
  background: #fff;
  border-radius: 3px;
  border: solid 1px #505a5e;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #505a5e;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
}

.ta-services__form .ta-services__form__btn-add i {
  font-size: 14px;
}

.ta-services__form .ta-services__form--outer-box {
  margin-bottom: 0;
}

.ta-services__form .ta-row__no-margin {
  margin-bottom: 0;
}

.ta-services__form .ta-row__no-margin .ta-hover-popup {
  width: 100%;
}

.ta-list-item__participant:before {
  color: #b6babd;
}

.ta-services__form .ta-bordered-box .ta-hover-popup {
  width: 100%;
}

.ta-services__form .ta-buffer-times .ta-striped-title__label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
}

.ta-buffer-times {
  padding-left: 32px;
  position: relative;
}

.ta-buffer-times__box {
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 24px;
  height: 98px;
  border-radius: 3px;
}

.ta-buffer-times__box__content {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  width: 100%;
  border-radius: 3px;
}

.ta-bordered-box.ta-no-bottom__margin {
  margin-bottom: 0;
}

.ta-services__form .ta-col.vertical-center {
  vertical-align: middle;
}

.ta-service-form-inputs__popup {
  width: 100%;
}
