.ta-intervals-input {
  position: relative;
}

.ta-intervals-input__interval {
  margin-bottom: 8px;
  position: relative;
  padding-left: 55px;
}

.ta-intervals-input__interval__checkbox {
  position: absolute;
  top: 9px;
  left: 0;
  width: 55px;
  z-index: 1;
  cursor: pointer;
}

.ta-intervals-input__interval__checkbox.disabled {
  cursor: not-allowed;
}

.ta-intervals-input .ta-intervals-input__interval:last-child {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.ta-intervals-input__interval__from {
  display: inline-block;
  width: calc((100% - 20px) / 2);
}

.ta-intervals-input__interval.has-delete .ta-intervals-input__interval__from {
  width: calc((100% - 52px) / 2);
}

.ta-intervals-input__interval__separator {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  width: 20px;
  text-align: center;
  vertical-align: top;
  margin-top: 15px;
}

.ta-intervals-input__interval__until {
  display: inline-block;
  width: calc((100% - 20px) / 2);
}

.ta-intervals-input__interval.has-delete .ta-intervals-input__interval__until {
  width: calc((100% - 52px) / 2);
}

.ta-intervals-input__interval__btn-delete {
  cursor: pointer;
  width: 24px;
  height: 40px;
  line-height: 38px;
  background: #b6babd;
  color: #505a5e;
  border-radius: 6px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
}

.ta-intervals-input__btn-add {
  background: #b6babd;
  border-radius: 6px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-left: 55px;
  cursor: pointer;
}

.ta-intervals-input__interval__btn-delete.top-offset {
  top: 20px;
}

.ta-intervals-input__interval__btn-delete.disabled,
.ta-intervals-input__btn-add.disabled {
  background-color: #e1e3e4;
  color: rgba(80, 90, 94, 0.3);
  cursor: not-allowed;
}

.ta-intervals-input__interval__btn-delete.disabled:hover,
.ta-intervals-input__btn-add.disabled:hover {
  background-color: #dadcde;
}

.ta-intervals-input.disabled .ta-intervals-input__btn-add {
  display: none;
}

.ta-intervals-input.disabled .ta-intervals-input__interval__btn-delete {
  opacity: 0.5;
  cursor: default;
}

.ta-intervals-input.disabled .ta-intervals-input__interval__separator {
  opacity: 0.3;
}

.ta-services__form__times .ta-checkbox.active.disabled {
  cursor: not-allowed;
}

.ta-intervals-input__interval__gap {
  text-align: center;
  font-size: 12px;
  opacity: 0.7;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #505a5e;
  text-transform: uppercase;
  margin-bottom: 8px;
}