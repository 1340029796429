.ta-attributes {
  animation: fade-in 0.3s 1;
}

.ta-attributes .ta-fixed-content__body {
  padding: 15px;
}

.ta-attributes__title {
  padding: 20px 0 15px;
}

.ta-attributes .ta-title__content {
  gap: 4px;
}

.ta-attributes .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-attributes .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-attributes .ta-simple-tabs__header:after {
  content: "";
  position: absolute;
  top: 30px;
  left: 0px;
  height: 15px;
  width: 100%;
  z-index: 9999;
  background: -moz-linear-gradient(top, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-attributes .ta-simple-tabs__body {
  padding: 24px;
}

.ta-attributes__categories-list__add-btn i {
  margin-right: 4px;
}

.ta-attributes__list .ta-simple-tabs__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f4f3f3;
  padding: 20px;
  overflow-y: auto;
}

.ta-attributes__category .ta-accordion__title:after {
  right: 118px;
}

.ta-attributes__category.read .ta-accordion__title:after {
  right: 33px;
}

.ta-attributes__category .ta-section-title {
  margin-top: 0;
}

.ta-attributes__category .ta-section-title__label {
  max-width: calc(100% - 132px);
}

.ta-attributes__attribute.ta-bordered-box {
  position: relative;
  border-radius: 6px;
  padding: 12px;
  padding-left: 16px;
  cursor: pointer;
  margin-bottom: 8px;
}

.ta-attributes__attribute.ta-bordered-box:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-attributes__attribute.ta-bordered-box:after {
  content: '';
  position: absolute;
  inset: 4px auto 4px 4px;
  width: 4px;
  border-radius: 2px;
  background: #EBB65D;
}

.ta-attributes__attribute.ta-bordered-box.available-in-widget:after {
  background: #82A774;
}

.ta-attributes__attribute__name {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 4px;
}

.ta-attributes__attribute__type {
  color: #505A5E;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-bottom: 4px;
}

.ta-attributes__attribute__visibility {
  color: #505A5E;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.ta-attributes__attribute__visibility i {
  margin-right: 2px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}