.ta-accounts-switch {
  border-bottom: 1px solid #1d3247;
  background: #25405a;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  height: 38px;
  line-height: 39px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.ta-accounts-switch:hover {
  color: #CB3B43;
}

.ta-accounts-switch.active {
  background: #fff;
  color: #505a5e;
}

.ta-accounts-switch.no-cursor {
  cursor: default;
}

.ta-accounts-switch.no-cursor .ta-accounts-switch__arrow {
  display: none;
}

.ta-accounts-switch__label {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  padding: 0 10px 0 33px;
}

.ta-accounts-switch.active .ta-accounts-switch__arrow {
  display: none;
}

.ta-accounts-switch__arrow {
  position: absolute;
  left: 100%;
  top: 0;
}

.ta-accounts-switch__arrow__icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  font-weight: bold;
}

.ta-accounts-switch__icon {
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
  font-size: 13px;
}
