.ta-booking-settings__title {
  padding: 20px 0 15px;
}

.ta-booking-settings .ta-title__content .ta-btn,
.ta-booking-settings__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-booking-settings .ta-title__content> :not(:first-child),
.ta-booking-settings__preview .ta-title__content> :not(:first-child) {
  margin-left: 4px;
}

.ta-booking-settings .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 4px;
  display: inline-block;
}

.ta-booking-settings .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-booking-settings__form .ta-fixed-content__body {
  padding-top: 10px;
}

.ta-booking-settings .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-booking-settings .ta-simple-tabs__header {
  position: relative;
}

.ta-booking-settings .ta-simple-tabs__header:after {
  content: "";
  position: absolute;
  top: 30px;
  left: 0px;
  height: 15px;
  width: 100%;
  z-index: 999;
  background: -moz-linear-gradient(top, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: linear-gradient(to bottom, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-settings__form .ta-section-title {
  margin-top: 0;
  margin-bottom: 8px;
}

.ta-settings__form .ta-simple-tabs__body {
  margin-bottom: 8px;
}

.ta-booking-settings .ta-form-section {
  margin-bottom: 0;
}

.ta-booking-settings .ta-section-title {
  margin-bottom: 4px;
}

.ta-settings__form .ta-simple-tabs__body {
  margin-bottom: 8px;
}

.ta-booking-settings .ta-section-title:first-of-type {
  margin-top: 0;
}

.ta-booking-settings .ta-maximum-lead-time-example {
  margin-bottom: 12px;
}

.ta-booking-settings .ta-minimum-lead-time-example {
  margin-bottom: 3px;
}

.ta-settings__form .ta-form {
  margin-top: 15px;
}

.ta-settings__form .ta-checkbox.space-bottom {
  margin-bottom: 8px;
}

.ta-booking-settings__calendar .ta-bordered-box.no-border {
  border: none;
  padding: 0;
}

.ta-list.ta-notifications-settings__item {
  line-height: 1;
  padding: 4px 0;
  background-color: #f4f3f3;
}

.ta-booking-settings__calendar .ta-list.ta-notifications-settings__item:before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  color: #b6babd;
  margin-right: 6px;
  font-weight: bold;
  font-size: 6px;
  position: relative;
  left: 0;
  top: -2px;
}

.ta-booking-settings__calendar .ta-booking-settings__calendar__holidays .ta-list {
  padding-top: 0;
}

.ta-booking-settings__calendar .ta-booking-settings__calendar__holidays strong {
  font-weight: bold;
  font-size: 12px;
  color: #505a5e;
  line-height: 1.33;
}

.ta-booking-settings__calendar .ta-booking-settings__calendar__holidays li {
  list-style-type: none;
  position: relative;
  padding-left: 12px;
}

.ta-booking-settings__calendar .ta-booking-settings__calendar__holidays li::before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  margin-right: 6px;
  font-weight: bold;
  font-size: 6px;
  position: absolute;
  left: 0;
  top: 4px;
}

.ta-booking-settings__calendar .ta-settings-booking__calendar__holiday-value-item {
  display: inline-block;
  opacity: 1;
  border-radius: 4px;
  background-color: #a4a8aa;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  margin: 4px 4px 0 0;
  cursor: default;
  height: 24px;
  transition: background-color .5s,opacity .5s;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  white-space: nowrap;
  line-height: 24px;
}

.ta-booking-settings__calendar.ta-settings-booking__calendar__holiday-value-item-label {
  display: inline-block;
}

.ta-booking-settings__calendar.ta-settings-booking__calendar__holiday-value-item-avatar {
  display: inline-block;
}

.ta-booking-settings__calendar.ta-settings-booking__calendar__holiday-value-item-avatar .ta-avatar {
  width: 12px;
  height: 12px;
  line-height: 10px;
  font-size: 7px;
  margin-bottom: -2px;
  margin-right: 4px;
}

.ta-booking-settings__calendar.ta-settings-booking__calendar__holiday-value-item-avatar .ta-avatar .ta-avatar__image,
.ta-settings-booking__calendar .ta-settings-booking__calendar__holiday-value-item-avatar .ta-avatar .ta-avatar__image img {
  width: 12px;
  height: 12px;
}

.ta-booking-settings .ta-list a {
  color: #385f87;
}

.ta-hint-text.offset-left {
  margin-left: 24px;
}

.ta-settings__form .ta-form .ta-radio:after {
  background: #fff;
}

.ta-settings__form .ta-form .ta-checkbox:before {
  z-index: 1;
}

.ta-settings__form .ta-settings-calendar__holiday-container {
  position: relative;
}

.ta-settings__form .ta-settings-booking__calendar__holidays {
  overflow: hidden;
}

.ta-settings__form .ta-settings-booking__calendar__holiday-value-item {
  display: inline-block;
  opacity: 1;
  border-radius: 4px;
  background-color: #a4a8aa;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  margin: 4px 4px 0 0;
  cursor: default;
  height: 24px;
  transition: background-color .5s,opacity .5s;
}

.ta-settings__form .ta-settings-booking__calendar__holiday-value-item-label {
  display: inline-block;
}

.ta-settings__form .ta-settings-booking__calendar__holiday-value-item-avatar {
  display: inline-block;
}

.ta-settings__form .ta-settings-booking__calendar__holiday-value-item-avatar .ta-avatar {
  width: 12px;
  height: 12px;
  line-height: 10px;
  font-size: 7px;
  margin-bottom: -2px;
  margin-right: 4px;
}

.ta-settings__form .ta-settings-booking__calendar__holiday-value-item-avatar .ta-avatar .ta-avatar__image,
.ta-settings__form .ta-settings-booking__calendar__holiday-value-item-avatar .ta-avatar .ta-avatar__image img {
  width: 12px;
  height: 12px;
}

.ta-settings__form .ta-settings-booking__calendar__holiday-value-category:not(.first) .ta-striped-title {
  margin-top: 8px;
}

.ta-settings__form .ta-striped-title .ta-striped-title__label {
  font-size: 10px;
  font-weight: bold;
}

.ta-settings__form .no-margin {
  margin: 0;
}

.ta-settings__form .ta-accordion,
.ta-booking-settings__calendar .ta-accordion,
.ta-booking-settings__booking .ta-accordion {
  border-radius: 3px;
  border: solid 1px #969c9e;
  margin-bottom: 8px;
}

.ta-settings__form .ta-accordion .ta-accordion__title,
.ta-booking-settings__calendar .ta-accordion .ta-accordion__title,
.ta-booking-settings__booking .ta-accordion .ta-accordion__title {
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
}

.ta-settings__form .ta-accordion .ta-accordion__title span,
.ta-booking-settings__calendar .ta-accordion .ta-accordion__title span,
.ta-booking-settings__booking .ta-accordion .ta-accordion__title span {
  font-weight: 300;
}

.ta-settings__form .ta-accordion.expand .ta-accordion__title,
.ta-booking-settings__calendar .ta-accordion.expand .ta-accordion__title,
.ta-booking-settings__booking .ta-accordion.expand .ta-accordion__title {
  border-bottom: solid 1px #969c9e;
}

.ta-settings__form .ta-accordion.expand .ta-accordion__body,
.ta-booking-settings__calendar .ta-accordion.expand .ta-accordion__body,
.ta-booking-settings__booking .ta-accordion.expand .ta-accordion__body {
  padding: 8px;
}

.ta-settings__form .ta-accordion .ta-accordion__title:after,
.ta-booking-settings__calendar .ta-accordion .ta-accordion__title:after,
.ta-booking-settings__booking .ta-accordion .ta-accordion__title:after {
  background: #505A5E;
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  font-size: 8px;
  right: 8px;
}

.ta-settings__form .ta-accordion .ta-accordion__title i {
  position: absolute;
  right: 30px;
  font-size: 13px;
  color: #b6babd;
  cursor: pointer;
}

.ta-booking-settings__calendar .ta-accordion .ta-form-text {
  font-size: 12px;
}

.ta-settings__form .ta-checkbox-wrapper.no-top-offset .ta-form-group {
  padding-top: 0;
}

.ta-settings__form .ta-bordered-box {
  margin-bottom: 0px;
}

.ta-settings__form .ta-rich-text__editor.short .ql-editor {
  min-height: 64px;
  max-width: 395px;
}

.ta-settings__form .ta-rich-text.big .ta-rich-text__editor.short .ql-editor {
  min-height: 98px;
}

.ta-booking-settings .ta-disclaimer-container {
  display: flex;
  align-items: flex-start;
}

.ta-booking-settings .ta-disclaimer-container p {
  word-break: break-word;
}

.ta-booking-settings .ta-disclaimer-container .ta-disclaimer-dot {
  position: relative;
  background: #b6babd;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 5px;
  margin-right: 6px;
}

.ta-booking-settings .ta-disclaimer-container a {
  text-decoration: initial;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ta-settings__form .ta-fixed-content__body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ta-settings__form .ta-fixed-content__body::-webkit-scrollbar {
  display: none;
}

/* Service allocations */
.ta-form-section.service-allocations .ta-message.active {
  margin-bottom: 16px;
  max-width: 100%;
}

.ta-form-section.service-allocations .ta-message.no-margin.active {
  margin-bottom: 0px;
}

.ta-form-section.service-allocations .ta-message {
  word-break: break-word;
}

.ta-form-section.service-allocations .ta-accordion.expand .ta-accordion__title:after {
  content: "\F0D8";
}

.ta-form-section.service-allocations .ta-accordion .ta-accordion__title:after {
  content: "\F0D7";
}

.ta-form-section.service-allocations .ta-settings__btn-add.service-allocations {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-weight: 900;
}

.ta-form-section.service-allocations .ta-settings__btn-add.service-allocations.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-form-section.service-allocations .ta-settings__btn-add.service-allocations i {
  margin-right: 8px;
  font-size: 14px;
  font-weight: 900;
}

.ta-form-section.service-allocations .ta-striped-title__label {
  font-size: 10px;
}

.ta-form-section.service-allocations .ta-services-allocations__settings-border {
  flex-grow: 1;
  height: 1px;
  background: #d3d6d7;
}

.ta-form-section.service-allocations .ta-popup__trigger {
  font-size: 12px;
  color: #979D9F;
  margin-left: 8px;
  cursor: pointer;
}

.ta-popup .ta-popup__content.ta-service-allocations .ta-popup__content-inner {
  padding: 15px;
  word-break: break-all;
}

.ta-form-section.service-allocations .ta-select.ta-single-select {
  position: relative;
}

.ta-settings__form .ta-form-section.service-allocations .ta-accordion .ta-accordion__title .ta-allocation-label {
  border-radius: 6px;
  padding: 0 4px;
  font-weight: 900;
  line-height: 1.5;
  font-size: 8px;
  color: #fff;
  background: #a7acae;
  position: absolute;
  right: 50px;
  top: 8px;
}

.ta-form-section.service-allocations .ta-select .ta-multi-select__values-item {
  opacity: 0.7;
}

.ta-form-section.service-allocations .ta-form-error {
  word-break: break-all;
}

.ta-form-section.service-allocations .ta-select.ta-single-select {
  position: relative;
}

.ta-form-section.service-allocations .ta-select.ta-single-select .ta-select__arrow {
  top: 11px;
}

.ta-booking-settings__booking .ta-service-allocations__container {
  background-color: #fff;
}

.ta-service-allocations__container .ta-accordion__body .ta-striped-title .ta-striped-title__label {
  background-color: #fff;
}

.ta-booking-settings__booking .ta-service-allocations__container .ta-allocation-label,
.ta-settings-booking__form .ta-form-section.service-allocations .ta-accordion .ta-accordion__title .ta-allocation-label {
  border-radius: 6px;
  padding: 0 4px;
  font-weight: 900;
  line-height: 1.5;
  font-size: 8px;
  color: #fff;
  background: #a7acae;
  position: absolute;
  right: 50px;
  top: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 109px;
  overflow: hidden;
}

.ta-booking-settings__booking .ta-service-allocations__container .ta-allocation-label {
  right: 30px;
}

.ta-booking-settings__booking .ta-service-allocations-categories__label {
  font-size: 12px;
  font-weight: normal;
  color: #505a5e;
  margin-bottom: 8px;
}

.ta-service-allocations-categories__percent-label {
  border: solid 1px rgba(235, 182, 93, 0.4);
  background-color: rgba(235, 182, 93, 0.2);
  padding: 1px 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  position: relative;
  top: -2px;
}

.ta-booking-settings__booking .ta-service-allocations-categories__label.percent {
  margin: 8px 0 0 0;
}

.ta-form-section.service-allocations .ta-select .ta-multi-select__values-item {
  opacity: 0.7;
}

.ta-form-section.service-allocations .ta-form-error {
  word-break: break-all;
}

.ta-booking-settings__booking .ta-service-allocations__services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.ta-booking-settings__booking .ta-service-allocations__allocation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #a7acae;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 4px 4px 0 0;
  cursor: default;
  height: 24px;
  transition: background-color .5s,opacity .5s;
}

.ta-booking-settings__booking .ta-service-allocations__allocation-container .ta-service-allocations__allocation-name {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  white-space: nowrap;
  height: 24px;
  line-height: 24px;
}

.ta-form-section.service-allocations .ta-list.bullet-item:before {
  color: #505a5e;
}

.ta-form-section.service-allocations .ta-striped-title {
  margin-bottom: 0px;
}

.ta-form-section.service-allocations .ta-service-allocation__lead-time__box {
  margin-top: 4px;
}

.ta-booking-settings__booking .ta-accordion .ta-accordion__title span.ta-allocation-label {
  font-weight: 900;
}

.ta-settings-ics__borderd_box_container {
  max-width: 430px;
}

.ta-settings-ics__borderd_box_container .ta-select,
.ta-settings-ics__borderd_box_container .ta-select__options {
  max-width: 410px;
}

.ta-settings-ics__list_items {
  margin-left: 8px
}

.ics-file-customisation .ta-list {
  white-space: break-spaces;
}

.ta-settings__ics_custom_box {
  width: 100%;
}

.ta-settings-ics__list_indented {
  padding-left: 8px;
}

.ta-settings-ics__list_container .ta-list.bullet-item:before {
  color: #505a5e
}

.ta-settings-ics__list_container .ta-list.bullet-item {
  padding: 0;
}

.ta-settings-ics__borderd_box_container .ta-hint-text {
  margin-top: 0;
}

.ta-settings__ics_customisations-warning-container.ta-message.type-warning {
  margin-bottom: 0;
  margin-top: 8px;
}

.ta-settings__ics_tooltip_container {
  padding-top: 3px;
}

.ta-settings-widget__disclaimer.ta-rich-text {
  padding-top: 0;
}

.ta-settings-widget__disclaimer.ta-rich-text .ta-rich-text__label {
  position: relative;
}

.ta-settings-ics__other_address_container {
  margin-top: 8px;
}

.ta-settings-booking__calendar__holidays-accordion {
  background-color: #fff;
}

.ta-settings-booking__calendar__holidays-accordion .ta-accordion__body .ta-striped-title .ta-striped-title__label {
  background-color: #fff;
}

.ta-select.ta-single-select .ta-select__values__category .ta-striped-title .ta-striped-title__label,
.ta-bordered-box.ta-settings-ics__borderd_box_container .ta-striped-title .ta-striped-title__label {
  background-color: #fff;
}

.ta-booking-settings__widget a.ta-btn {
  padding: 0;
  height: auto;
}