.ta-minimum-lead-time-example {
  margin-top: 5px;
  position: relative;
}

.ta-minimum-lead-time-example__label {
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.8;
  margin: 5px  0;
  line-height: 15px;
  text-align: center;
  color: #505a5e;
}

.ta-minimum-lead-time-example__now {
  position: absolute;
  top: 40px;
  left: -15px;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  text-transform: uppercase;
  width: 40px;
  text-align: center;
  transform: rotate(-90deg);
}

.ta-minimum-lead-time-example__hours {
  font-size: 8px;
  font-weight: 400;
  color: #505a5e;
  position: absolute;
  top: 5px;
  left: 8px;
  right: 0;
  text-align: center;
}

.ta-minimum-lead-time-example__timeline {
  height: 60px;
  position: relative;
  min-width: 418px;
}

.ta-minimum-lead-time-example__timeline-active {
  position: relative;
  border-bottom: 2px solid #505a5e;
  height: 20px;
  width: 363px;
  display: inline-block;
  transition: all .5s;
}

.ta-minimum-lead-time-example__timeline-active:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #fff;
  border: 2px solid #505a5e;
  position: absolute;
  top: 15px;
  left: 0;
}

.ta-minimum-lead-time-example__timeline-active:after {
  content: "";
  border: 5px solid #505a5e;
  border-color: transparent transparent transparent #505a5e;
  position: absolute;
  top: 14px;
  right: -8px;
}

.ta-minimum-lead-time-example__timeline-inactive {
  position: relative;
  border-bottom: 2px dashed #c9c8c6;
  height: 20px;
  width: 50px;
  display: inline-block;
  transition: all .5s;
}

.ta-minimum-lead-time-example__timeline-inactive:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #505a5e;
  position: absolute;
  top: 15px;
  left: 0;
}

.ta-minimum-lead-time-example__timeline-slots {
  position: absolute;
  top: 26px;
  left: 60px;
}

.ta-minimum-lead-time-example__slot {
  position: relative;
  float: left;
  margin-right: 5px;
  background: #505a5e;
  height: 20px;
  line-height: 20px;
  width: 39px;
  border-radius: 3px;
  font-size: 10px;
  transition: all .5s;
}

.ta-minimum-lead-time-example__slot:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  color: #fff;
  transform: translate(-50%, -50%);
}

/* 2 hours */

.ta-minimum-lead-time-example.hours-2 .ta-minimum-lead-time-example__timeline-inactive {
  width: 98px;
}

.ta-minimum-lead-time-example.hours-2 .ta-minimum-lead-time-example__timeline-active {
  width: 314px;
}

.ta-minimum-lead-time-example.hours-2 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-2 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

/* 4 hours */

.ta-minimum-lead-time-example.hours-4 .ta-minimum-lead-time-example__timeline-inactive {
  width: 142px;
}

.ta-minimum-lead-time-example.hours-4 .ta-minimum-lead-time-example__timeline-active {
  width: 270px;
}

.ta-minimum-lead-time-example.hours-4 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-4 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-4 .ta-minimum-lead-time-example__slot.slot-2 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-4 .ta-minimum-lead-time-example__slot.slot-2:after {
  content: "\f00d";
}

/* 8 hours */

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__timeline-inactive {
  width: 185px;
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__timeline-active {
  width: 227px;
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__slot.slot-2 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__slot.slot-2:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__slot.slot-3 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-8 .ta-minimum-lead-time-example__slot.slot-3:after {
  content: "\f00d";
}

/* 12 hours */

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__timeline-inactive {
  width: 230px;
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__timeline-active {
  width: 182px;
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-2 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-2:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-3 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-3:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-4 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-12 .ta-minimum-lead-time-example__slot.slot-4:after {
  content: "\f00d";
}

/* 24 hours */

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__timeline-inactive {
  width: 274px;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__timeline-active {
  width: 138px;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-2 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-2:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-3 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-3:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-4 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-4:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-5 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-24 .ta-minimum-lead-time-example__slot.slot-5:after {
  content: "\f00d";
}

/* 48 hours */

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__timeline-inactive {
  width: 318px;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__timeline-active {
  width: 94px;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-2 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-2:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-3 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-3:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-4 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-4:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-5 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-5:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-6 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-48 .ta-minimum-lead-time-example__slot.slot-6:after {
  content: "\f00d";
}

/* 72 hours */

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__timeline-inactive {
  width: 363px;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__timeline-active {
  width: 49px;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-1 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-1:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-2 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-2:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-3 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-3:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-4 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-4:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-5 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-5:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-6 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-6:after {
  content: "\f00d";
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-7 {
  opacity: 0.3;
}

.ta-minimum-lead-time-example.hours-72 .ta-minimum-lead-time-example__slot.slot-7:after {
  content: "\f00d";
}
