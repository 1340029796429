.ta-accounts .ta-fixed-content:before {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
}

.ta-accounts__list {
  padding: 10px 0;
}

.ta-accounts .ta-fixed-content:before, .ta-accounts .ta-fixed-content__body {
  top: 155px;
}

.ta-accounts__title {
  background: #e4e6e8;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  margin: 2px -13px;
  font-size: 16px;
  font-weight: 500;
  color: #505a5e;
}

.ta-accounts__title i {
  margin-right: 10px;
}

.ta-accounts__item {
  border-bottom: 1px solid #c9c8c6;
  margin-bottom: 9px;
  height: 59px;
  padding-left: 60px;
  padding-right: 50px;
  position: relative;
  cursor: pointer;
}

.ta-accounts__item.is-pending {
  cursor: default;
  padding-right: 120px;
}

.ta-accounts__item:after {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  right: 0;
  top: 16px;
}

.ta-accounts__item.is-pending:after {
  display: none;
}

.ta-accounts__item.selected {
  cursor: default;
  height: 64px;
}

.ta-accounts__item.selected:after {
  display: none;
}

.ta-accounts__list .ta-accounts__item:last-child {
  border-bottom: none;
}

.ta-accounts__item__name {
  font-size: 14px;
  font-weight: 500;
  color: #505a5e;
  padding: 9px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-accounts__item.is-pending .ta-accounts__item__name {
  opacity: 0.5;
}

.ta-accounts__item.no-address .ta-accounts__item__name {
  line-height: 50px;
  padding: 0;
}

.ta-accounts__item__map {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid rgba(45, 47, 51, 0.2);
  background: #fff;
  text-align: center;
  overflow: hidden
}

.ta-accounts__item.is-pending .ta-accounts__item__map {
  opacity: 0.5;
}

.ta-accounts__item__address {
  color: #505a5e;
  font-weight: 300;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-accounts__item.is-pending .ta-accounts__item__address {
  opacity: 0.5;
}

.ta-accounts__item__label {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  top: 13px;
  right: 20px;
  background: #f9f9f9;
  font-size: 9px;
}

.ta-accounts__item.is-pending .ta-accounts__item__label {
  display: none;
}

.ta-accounts__item__label.owner {
  color: #505a5e;
  background: #ecd007;
}

.ta-accounts__item__label.admin {
  color: #fff;
  background: #cb3b43;
}

.ta-accounts__item__label.custom {
  color: #fff;
  background: #385f87;
}

.ta-accounts__item__initials {
  line-height: 3.5;
  vertical-align: middle;
  font-weight: bold;
  color: #505a5e;
}

.ta-accounts__item__map img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: relative;
  top: -1px; 
  left: -1px;
}

.ta-accounts .ta-google-static-map__img {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

.ta-accounts__item__invitation__cta {
  position: absolute;
  top: 13px;
  right: 0;
  font-size: 10px;
  font-weight: 300;
  color: #505a5e;
  cursor: pointer;
  text-decoration: underline;
  display: none;
  background: rgba(203, 59, 67, 0.15);
  border-radius: 3px;
  padding: 0 8px;
  height: 25px;
  line-height: 25px;
}

.ta-accounts__item__invitation__cta i {
  margin-right: 5px;
}

.ta-accounts__item.is-pending .ta-accounts__item__invitation__cta {
  display: inline-block;
}

.ta-accounts .ta-form {
  padding: 10px 0 0 0;
}