.ta-attributes__category-form__name-input {
  margin-left: 60px;
}

.ta-attributes__category-form__name-input .ta-form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ta-attributes__category-form__icon-select .ta-icon-select__selected {
  border: 1px solid rgba(80, 90, 94, 0.4);
  border-right: none;
}


.ta-attributes__category-form__name-input .ta-form-control__hint,
.ta-attributes__category-form__name-input .ta-error {
  margin-left: -60px;
}

.ta-attributes__category-form__submit-btn i {
  margin-right: 4px;
}