.ta-form {
  padding-bottom: 30px;
}

.ta-form-error {
  background: rgba(203, 59, 67, 0.1);
  color: #cb3b43;
  padding: 10px;
  padding-left: 32px;
  margin-top: 5px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 100;
  min-height: 32px;
  position: relative;
  display: none;
}

.ta-form-error.active {
  display: block;
}

.ta-form-error.multi-fields {
  margin-top: -5px;
  margin-bottom: 10px;
}

.ta-form-error.ta-confirmation__error {
  margin-top: 10px;
}

.ta-form-error:before {
  content: "\f071";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
}

.ta-form-section {
  margin-bottom: 16px;
}

.ta-form-section.ignore-bottom-offset {
  margin-bottom: 0;
}

.ta-form-group {
  position: relative;
}

.ta-form-group label {
  position: absolute;
  top: 15px;
  left: 9px;
  font-size: 10px;
  font-weight: bold;
  color: rgba(80, 90, 94, 0.6);
  z-index: 5;
  transition: all .2s;
  max-width: calc(100% - 48px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1;
}

.ta-form-group.disabled {
  cursor: not-allowed;
}

.ta-form-control {
  display: block;
  width: 100%;
  border: 1px solid rgba(80, 90, 94, 0.4);
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  font-size: 10px;
  font-weight: normal;
  color: rgba(80, 90, 94, 0.6);
  outline: none;
  transition: all 0.5s;
  line-height: 1.33;
}

.ta-form-control:hover {
  color: rgba(80, 90, 94, 0.9);
  border-color: #505a5e;
  background-color: #edeeee;
}

.ta-form-control textarea {
  width: 100%;
  line-height: 18px;
  border: none;
  padding: 0;
  resize: none;
  transition-property: border-color, background-color;
}

.ta-form-control:hover textarea {
  color: rgba(80, 90, 94, 0.9);
}

input.ta-form-control {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

input.ta-form-control::placeholder {
  height: 40px;
  line-height: 40px;
}

input.ta-form-control.has-addon {
  padding-right: 54px;
}

.ta-form-group.hasError:not(.focused):not(.active) .ta-form-control {
  border-color: #cb3b43;
  color: rgba(203, 59, 67, 0.7);
}

.ta-form-group.hasError:not(.focused):not(.active) .ta-form-control::placeholder {
  color: rgba(203, 59, 67, 0.7);
}

.ta-form-group.hasError:not(.focused):not(.active) .ta-form-control textarea {
  color: rgba(203, 59, 67, 0.7);
}

.ta-form-control__addon {
  position: absolute;
  top: 13px;
  right: 10px;
  font-size: 14px;
  font-weight: normal;
  color: rgba(80, 90, 94, 0.7);
  line-height: 1;
}

.ta-form-control__addon.is-text {
  font-size: 10px;
  font-weight: bold;
  top: 15px;
  right: 8px;
}

.ta-form-group.focused .ta-form-control__addon,
.ta-form-group.filled .ta-form-control__addon {
  color: #505a5e;
}

.ta-form-group.focused:not(.no-label) .ta-form-control__addon.is-text,
.ta-form-group.filled:not(.no-label) .ta-form-control__addon.is-text {
  top: 20px;
}

.ta-form-group.hasError:not(.focused):not(.active) .ta-form-control__addon {
  color: #cb3b43;
}

.ta-form-group.hasError:not(.focused):not(.active):not(.filled) .ta-form-control__addon {
  color: rgba(203, 59, 67, 0.7);
}

.ta-form-group.disabled .ta-form-control__addon {
  color: rgba(80, 90, 94, 0.3);
}

.ta-form-control__prefix {
  margin-right: -6px;
  color: rgba(80, 90, 94, 0.7);
  font-weight: 300;
  font-size: 13px;
  cursor: default;
  position: relative;
  background: #fff;
  border: 1px solid rgba(80, 90, 94, 0.4);
  border-right: none;
  border-radius: 6px 0 0 6px;
  height: 40px;
  line-height: 38px;
  padding-left: 10px;
  transition: all 0.5s;
  z-index: 1;
}

.ta-form-control__hint {
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: #505a5e;
  opacity: 0.5;
  margin-top: 8px;
}

.ta-form-control-wrapper {
  display: flex;
  align-items: center;
}

.ta-form-buttons>* {
  margin-right: 8px;
}

.ta-form-buttons>*:last-child {
  margin-right: 0;
}

.ta-form-group.filled label {
  visibility: visible;
  opacity: 1;
  color: rgba(80, 90, 94, 0.5);
  top: 6px;
  left: 8px;
  font-size: 9px;
  font-weight: bold;
  line-height: 13px;
}

.ta-form-group.filled .ta-form-control {
  border-color: rgba(80, 90, 94, 0.7);
  font-size: 12px;
  color: #505a5e;
  font-weight: normal;
}

.ta-form-group.filled .ta-form-control textarea {
  font-size: 12px;
  color: #505a5e;
  font-weight: normal;
}

.ta-form-group.filled .ta-form-control__prefix {
  border-color: rgba(80, 90, 94, 0.7);
}

.ta-form-group.focused label {
  visibility: visible;
  opacity: 1;
  color: #505a5e;
  top: 6px;
  left: 8px;
  font-size: 9px;
  font-weight: bold;
  line-height: 13px;
}

.ta-form-group.focused .ta-form-control {
  color: #505a5e;
  border-color: rgba(80, 90, 94, 0.8);
  background-color: #f6f7f7;
  font-size: 12px;
}

.ta-form-group.focused .ta-form-control textarea {
  color: #505a5e;
  font-size: 12px;
}

.ta-form-group.focused .ta-form-control__prefix {
  border-color: rgba(80, 90, 94, 0.8);
  background-color: #F6F7F7;
}

.ta-form-group:not(.focused) .ta-form-control:hover {
  box-shadow: 0 0 5px 0 rgba(80, 90, 94, 0.3);
  border-color: #505a5e;
}

.ta-form-group.focused:not(.no-label) .ta-form-control,
.ta-form-group.filled:not(.no-label) .ta-form-control {
  padding-top: 14px;
}

.ta-form-group.focused:not(.no-label) .ta-form-control.ta-textarea__wrapper,
.ta-form-group.filled:not(.no-label) .ta-form-control.ta-textarea__wrapper {
  padding-top: 18px;
}

.ta-form-group.disabled label {
  color: rgba(80, 90, 94, 0.3);
}

.ta-form-group.hasError:not(.focused):not(.active) label {
  color: rgba(203, 59, 67, 0.5);
}

.ta-form-group.hasError:not(.focused):not(.active):not(.filled) label {
  color: rgba(203, 59, 67, 0.7);
}

.ta-form-group.hasPrefix label {
  left: 38px;
}

.ta-form-group.disabled .ta-form-control {
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
  pointer-events: none;
  color: rgba(80, 90, 94, 0.3);
}

.ta-form-group.disabled .ta-form-control textarea {
  color: rgba(80, 90, 94, 0.3);
}

.ta-form-group.disabled .ta-form-control__prefix {
  color: rgba(80, 90, 94, 0.3);
  background-color: #f7f8f8;
  border-color: rgba(182, 186, 189, 0.3);
}

.ta-form-group:not(.disabled):not(.focused) .ta-form-control-wrapper:hover .ta-form-control__prefix,
.ta-form-group:not(.disabled):not(.focused) .ta-form-control-wrapper:hover .ta-form-control {
  border-color: #505A5E;
  background-color: #edeeee;
}

.ta-form-group.hasError:not(.focused):not(.active) .ta-form-control__prefix {
  color: rgba(203, 59, 67, 0.7);
  border-color: #cb3b43;
}

.ta-form-group.focused:not(.no-label) .ta-form-control__prefix.is-text,
.ta-form-group.filled:not(.no-label) .ta-form-control__prefix.is-text {
  line-height: 54px;
  font-size: 10px;
}

/* DISCARD POPUP */

.ta-form__discard-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6500;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}

.ta-form__discard-popup.active {
  visibility: visible;
  opacity: 1;
}

.ta-form__discard-popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
  background: -moz-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: -webkit-linear-gradient(top, #385f87 0%, #1d3247 100%);
  background: linear-gradient(to bottom, #385f87 0%, #1d3247 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#1d3247', startColorstr='#385f87',GradientType=0 );
}

.ta-form__discard-popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background: #fff;
  border-radius: 5px;
  padding: 16px;
}

.ta-form__discard-popup__content .ta-btn {
  margin-right: 4px;
}