.ta-services__preview.global.edited .ta-simple-tabs.scrollable-content .ta-simple-tabs__body,
.ta-services__preview.global.marked-for-deletion .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 200px;
}

.ta-services__preview.global.edited .ta-alert.ta-service__alert-height-fix {
  height: 147px;
}

.ta-services__preview.global .ta-simple-tabs.scrollable-content .ta-simple-tabs__body {
  top: 143px;
}

.ta-services__preview.global .ta-message.active {
  min-height: 88px;
}

.ta-services__preview.global .ta-message .ta-popup__trigger {
  display: block;
  margin-top: 16px;
}

.ta-services__preview.global.edited .ta-message .ta-popup__trigger {
  width: 65px;
}

.ta-services__preview.global.marked-for-deletion .ta-message .ta-popup__trigger {
  width: 110px;
}

.ta-services__preview .ta-btn-reset-global,
.ta-services__preview.global .ta-btn-delete-global {
  margin-top: 16px;
  width: min-content;
}

.ta-services__preview__details .ta-simple-table {
  text-align: center;
}

.ta-services__preview__details__header {
  position: relative;
  margin-bottom: 20px;
}

.ta-services__preview__details__status {
  display: inline-block;
  padding: 0 5px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  background: #b6babd;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.ta-services__preview__details__status.active {
  background: #769851;
  color: #fff;
}

.ta-services__preview__details__owner {
  border: 2px solid #f4f3f3;
  background: #ecd007;
  font-size: 13px;
  color: #505a5e;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(14px);
}

.ta-services__preview__details .ta-user-preview__name {
  margin-top: -5px;
  margin-bottom: 0;
}

.ta-services__preview .ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item {
  padding-bottom: 20px;
}

.ta-services__preview .ta-simple-tabs__body-item.ta-simple-tabs-courses,
.ta-services__preview .ta-simple-tabs__body-item.ta-simple-tabs-services {
  padding-top: 5px;
}

.ta-services__preview .ta-services__preview__courses .ta-no-results,
.ta-services__preview .ta-services__preview__services .ta-no-results {
  margin-top: 15px;
}

.ta-services__preview .ta-services__preview__courses .ta-simple-list-item__title,
.ta-services__preview .ta-services__preview__services .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: 1px;
}

.ta-services__preview__details__color {
  position: static;
}

.ta-services__preview__details__confirmed {
  display: inline-block;
  background: rgba(118, 152, 81, 0.15);
  color: #769851;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
}

.ta-services__preview__details__invitation {
  background: rgba(203, 59, 67, 0.15);
  color: #cb3b43;
  border-radius: 3px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}

.ta-services__preview__details__invitation i {
  margin-right: 5px;
}

.ta-services__preview__details__invitation__cta {
  float: right;
  color: #505a5e;
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
}

.ta-services__preview__details .ta-icon-list .ta-dotted-timeline__item {
  padding: 1px 0 1px 18px;
}

.ta-services__preview__details .ta-icon-list .ta-dotted-timeline__item.small {
  padding: 0 0 0 18px;
}

.ta-services__preview__details .ta-services__preview__details--info {
  margin-bottom: 10px;
}

.ta-services__preview__details .ta-list>strong li {
  padding-bottom: 8px;
}

.ta-services__preview--changed-locally form {
  padding-bottom: 0;
}

.ta-services__preview__details .ta-services__preview__details__description {
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.ta-services__preview__details .ta-services__preview__details__description a {
  color: #385f87;
  text-decoration: underline;
}

.ta-services__preview__details .ta-services__preview__details__description em,
.ta-services__preview__details .ta-services__preview__details__description i:not(.fas):not(.far):not(.fal):not(.fab) {
  font-style: italic;
}

.ta-services__preview__details .ta-services__preview__details__description b,
.ta-services__preview__details .ta-services__preview__details__description strong {
  font-weight: bold;
}

.ta-services__preview__service.ta-simple-list-item {
  border-color: #cbced0;
  padding: 8px;
  padding-left: 48px !important;
  margin-bottom: 4px;
}

.ta-services__preview__service.ta-simple-list-item li {
  padding-left: 0;
}

.ta-services__preview__service.ta-simple-list-item li:before {
  display: none;
}

.ta-services__preview__service.ta-simple-list-item:hover:after,
.ta-services__preview__service.ta-simple-list-item:after {
  top: 4px;
  bottom: 4px;
  width: 4px;
  left: 36px;
  border-radius: 3px;
}

.ta-services__preview__service.ta-simple-list-item .ta-list-button {
  height: 46px;
}

.ta-services__preview__service__index {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 52px;
  line-height: 52px;
  border-right: 1px solid #cbced0;
  color: rgba(80, 90, 94, 0.6);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.ta-services__preview__service.ta-simple-list-item:hover .ta-services__preview__service__index {
  border-color: #505a5e;
}

.ta-services__preview__advanced li {
  list-style-type: none;
  position: relative;
  padding-left: 12px;
}

.ta-services__preview__advanced .ta-list>strong li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ta-services__preview__advanced li:before {
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  margin-right: 6px;
  font-weight: bold;
  font-size: 6px;
  position: absolute;
  left: 0;
}

.ta-services__preview__advanced .ta-bordered-box,
.ta-services__preview__advanced .ta-form-section {
  margin-bottom: 0;
}

.ta-services__preview__advanced__last-section {
  padding-bottom: 20px;
}

.ta-services__preview__advanced .ta-list-item__participant:before {
  color: #b6babd;
}

.ta-services_preview-changed-locally-combination.ta-hover-popup.is-hover .ta-hover-popup__content {
  width: 300px;
}

.ta-services__preview--changed-locally-accordion .ta-accordion__title .ta-title {
  border: none;
}

.ta-services__preview--changed-locally-accordion.expand .ta-accordion__body {
  margin-top: 8px;
  padding: 16px 8px 8px 8px;
}

.ta-services__preview--changed-locally-accordion.expand .ta-accordion__body:before {
  content: " ";
  position: absolute;
  width: calc(100% + 16px);
  left: -8px;
  height: 1px;
  top: 0;
  background-color: #969c9e;
}

.ta-services__preview--changed-locally-accordion {
  padding: 8px;
  border-radius: 3px;
  border: solid 1px #cbced0;
  background-color: #fff;
  margin-bottom: 8px;
}

.ta-services__preview--changed-locally-accordion.expand {
  border: solid 1px #969c9e;
  padding-bottom: 8px;
}

.ta-services__preview--changed-locally-accordion .ta-title.small {
  margin-bottom: 0;
}

.ta-services__preview--changed-locally-accordion .ta-accordion__title>.ta-row {
  width: 90%;
}

.ta-services__preview--changed-locally-accordion .ta-accordion__title:after {
  content: "\f0d7";
  color: #fff;
  background-color: #505a5e;
  border-radius: 50%;
  padding: 4px 6px;
  font-size: 10px;
}

.ta-services__preview--changed-locally-accordion.expand .ta-accordion__title:after {
  content: "\f0d8";
  color: #fff;
  background-color: #505a5e;
  border-radius: 50%;
  padding: 4px 6px;
  font-size: 10px;
}

.ta-services__preview--changed-locally-accordion .ta-accordion__title:before {
  content: " ";
  border-left: 1px solid #cbced0;
  background-color: #cbced0;
  position: absolute;
  height: calc(100% + 16px);
  right: 26px;
  top: -8px;
}

.ta-services__preview--changed-locally-accordion.expand .ta-accordion__title:before {
  border-color: #969c9e;
}

.ta-services__preview--changed-locally-accordion-options {
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: #505a5e;
}

.ta-services__preview--changed-locally-accordion-option:not(:first-child) {
  margin-left: 8px;
}

.ta-services__preview--changed-locally-accordion .ta-icon-list__body {
  vertical-align: top;
}

.ta-simple-tabs.scrollable-content .ta-simple-tabs__body-item.ta-simple-tabs-changedLocally .ta-accordion .ta-accordion__body {
  overflow: hidden;
}

.services__preview_title_container {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding-bottom: 8px;
}

.services__preview_title_container .far.fa-clock {
  color: rgba(28, 39, 43, 0.6);
  font-size: 16px;
}

.services__preview_title_container .ta-title__label__text {
  font-size: 16px;
}