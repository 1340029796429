.ta-application {
  height: 100%;
  min-width: 1024px;
  min-height: 768px;
  overflow: hidden;
}

.ta-list-inline li {
  display: inline;
  padding-right: 10px;
}

/* BUTTONS */

.ta-btn {
  display: inline-block;
  height: 35px;
  line-height: 33px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 100;
  cursor: pointer;
  outline: none;
}

.ta-btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ta-btn:disabled {
  opacity: 0.5;
}

.ta-btn.bold {
  font-weight: bold;
}

.ta-btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.ta-btn-icon-only {
  width: 40px;
  padding: 0;
  text-align: center;
}

.ta-btn.ta-btn-primary {
  background: #cb3b43;
  border-color: #cb3b43;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.ta-btn.ta-btn-primary:hover {
  background: #ab3138;
}

.ta-btn.ta-btn-secondary {
  background: #b6babd;
  border-color: #b6babd;
  color: #505a5e;
}

.ta-btn.ta-btn-tertiary {
  background: #385f87;
  border-color: #385f87;
  color: #fff;
}

.ta-btn.ta-btn-tertiary:hover {
  background: #567799;
  border-color: #567799;
}

.ta-btn.ta-btn-dark {
  background: #505a5e;
  border-color: #505a5e;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.ta-btn.ta-btn-delete {
  background: #505a5e;
  border-color: #505a5e;
  color: #fff;
}

.ta-btn.ta-btn-black-ghost {
  background: none;
  border-color: #505a5e;
  color: #505a5e;
  font-weight: 500;
}

.ta-btn.ta-btn-grey-ghost {
  background: none;
  border-color: #505a5e;
  color: #505a5e;
}

.ta-btn.ta-btn-grey-ghost:hover {
  background: rgba(80, 90, 94, 0.15);
}

.btn-small-icon-only {
  color: #505a5e;
  font-size: 13px;
  cursor: pointer;
  opacity: 0.5;
}

.ta-start-tutorial-btn {
  margin-top: 8px;
}

.ta-btn.ta-btn__export {
  height: 40px;
  line-height: 38px;
  position: relative;
}

.ta-btn-download {
  padding-left: 30px;
  text-align: left;
  position: relative;
}

.ta-btn-download:after {
  content: "\f019";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px 0 0 3px;
}

/* GRID */

.ta-row {
  display: table;
  width: calc(100% + 8px);
  margin: 0 -4px;
  margin-bottom: 8px;
}

.ta-row.no-offset {
  margin-bottom: 0;
}

.ta-col {
  display: table-cell;
  padding: 0 4px;
  vertical-align: top;
}

.ta-col.col-10 {
  width: 10%;
}

.ta-col.col-15 {
  width: 15%;
}

.ta-col.col-20 {
  width: 20%;
}

.ta-col.col-23 {
  width: 23%;
}

.ta-col.col-25 {
  width: 25%;
}

.ta-col.col-30 {
  width: 30%;
}

.ta-col.col-33 {
  width: 33.333333%;
}

.ta-col.col-40 {
  width: 40%;
}

.ta-col.col-50 {
  width: 50%;
}

.ta-col.col-60 {
  width: 60%;
}

.ta-col.col-70 {
  width: 70%;
}

.ta-col.col-80 {
  width: 80%;
}

.ta-col.col-90 {
  width: 90%;
}

.ta-col.col-95 {
  width: 95%;
}

/* FLOAT */

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.highlight {
  background: #FFEEB2;
}

.relative {
  position: relative;
}

/* ROUTE NOT FOUND */

.ta-route-not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 3px;
  background: #fff;
  box-shadow: #505a5e 0 0 10px 0;
  width: 300px;
}

/* OTHERS */

.ta-stripe-active {
  color: #769851;
}

.ta-stripe-inactive {
  color: #b6babd;
}

.ta-separator {
  border-bottom: 1px solid #d4d6d7;
  margin-bottom: 16px;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ta-email {
  color: #385f87 !important;
}

.ta-verified {
  display: inline-block;
  height: 16px;
  border-radius: 3.3px;
  background-color: rgb(118, 152, 81, 0.15);
  color: #769851;
  font-weight: 600;
  font-size: 10px;
  padding: 3px 4px;
  line-height: 1.09;
}

/* Plans labels */

.ta-plan {
  display: inline-block;
  height: 16px;
  border-radius: 3.3px;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.09;
  color: #ffffff;
  padding: 4px;
  margin-right: 8px;
}

.ta-plan-classic {
  background-color: #ffffff;
  color: #505a5e;
}

.ta-plan-premium {
  background-color: #ecd007;
  color: #505a5e;
}

.ta-plan-enterprise {
  background-color: #cb3b43;
}

.ta-table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

.ta-table-row {
  display: table-row;
  width: 100%;
}

.ta-full-width {
  width: 100%;
}

.ta-cleaner {
  clear: both;
}
