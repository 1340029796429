.ta-dependencies-input {
  padding-left: 24px;
}

.ta-dependencies-input.one {
  padding-left: 0;
}

.ta-dependencies-input__group {
  position: relative;
}

.ta-dependencies-input__group:first-child:after {
  content: '';
  position: absolute;
  top: 20px;
  height: 100%;
  right: 100%;
  width: 16px;
  margin-right: 8px;
  border-top: 1px solid #CBCED0;
  border-left: 1px solid #CBCED0;
  border-top-left-radius: 6px;
}

.ta-dependencies-input__group:not(:first-child, :last-child):after {
  content: '';
  position: absolute;
  top: 20px;
  height: 1px;
  right: 100%;
  width: 16px;
  margin-right: 8px;
  background: #CBCED0;
}

.ta-dependencies-input__group:not(:first-child, :last-child):before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  bottom: -20px;
  width: 16px;
  margin-right: 8px;
  border-left: 1px solid #CBCED0;
}

.ta-dependencies-input__group:last-child:after {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  bottom: calc(100% - 20px);
  width: 16px;
  margin-right: 8px;
  border-bottom: 1px solid #CBCED0;
  border-left: 1px solid #CBCED0;
  border-bottom-left-radius: 6px;
}

.ta-dependencies-input.one .ta-dependencies-input__group:after,
.ta-dependencies-input.one .ta-dependencies-input__group:before {
  display: none;
}

.ta-dependencies-input__group>.ta-bordered-box {
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 6px;
}

.ta-dependencies-input__select-container.resources .ta-select .ta-multi-select__values-item {
  width: 100%;
  margin-right: 0
}

.ta-btn.ta-btn-secondary.ta-dependencies-input__buttons__btn-delete {
  cursor: pointer;
  width: 32px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ta-dependencies-input.one .ta-dependencies-input__buttons__btn-delete {
  display: none;
}

.ta-dependencies-input__select-container.categories {
  margin-right: 40px;
}

.ta-dependencies-input.one .ta-dependencies-input__select-container.categories {
  margin-right: 0;
}

.ta-dependencies-input .ta-bordered-box .ta-row:last-child {
  margin-bottom: 0;
}

.ta-dependencies-input__work-in-parallel {
  margin-top: 8px;
}

.ta-dependencies-input__work-in-parallel>.ta-form-text {
  line-height: 16px;
  padding-bottom: 8px;
}

.ta-dependencies-input__work-in-parallel>.ta-radio {
  padding-left: 28px;
}

.ta-btn.ta-btn-grey-ghost.ta-dependencies-input__add-btn {
  width: 100%;
  height: 32px;
  background: #fff;
  color: #505A5E;
  border: 1px solid #505A5E;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.ta-btn.ta-btn-grey-ghost.ta-dependencies-input__add-btn:hover {
  background-color: rgba(182, 186, 189, 0.15);
}

.ta-btn.ta-btn-grey-ghost.ta-dependencies-input__add-btn i {
  font-weight: 400;
}