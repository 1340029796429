.ta-menu {
  width: 100%;
  position: absolute;
  top: 90px;
  bottom: 115px;
  font-size: 11px;
  font-weight: 500;
}

.ta-menu>ul>li {
  border-bottom: 1px solid #1d3247;
  background: #25405a;
}

.ta-menu>ul>li>a {
  display: block;
  color: #fff;
  font-weight: 500;
  padding: 14px 0 12px;
  padding-left: 33px;
  cursor: pointer;
  position: relative;
}

.ta-menu>ul>li>a:hover {
  color: #cb3b43;
}

.ta-menu>ul>li>a span {
  display: block
}

.ta-menu>ul>li>a .ta-menu__icon {
  font-size: 12px;
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.icons-group i {
  margin: 0 1px;
}

.ta-menu>ul>li.active {
  background: #385f87;
}

.ta-menu>ul>li.active.no-childrens {
  background: #f4f3f3;
}

.ta-menu>ul>li.active>a {
  color: #fff;
}

.ta-menu>ul>li.active.no-childrens>a {
  color: #cb3b43;
}

.ta-menu>ul>li>ul {
  background: #1d3247;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

.ta-menu>ul>li.active ul {
  max-height: 210px;
}

.ta-menu>ul>li>ul>li {
  border-bottom: 1px solid #1d3247;
}

.ta-menu>ul>li>ul>li.active {
  background: #f4f3f3;
}

.ta-menu>ul>li>ul>li a {
  display: block;
  color: #fff;
  line-height: 11px;
  padding: 9px 0;
  padding-left: 11px;
  font-size: 10px;
  cursor: pointer;
}

.ta-menu>ul>li>ul>li a:hover {
  color: #cb3b43;
}

.ta-menu>ul>li>ul>li.active a {
  color: #cb3b43;
  position: relative;
}

.ta-menu>ul>li>ul>li.active a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 4px;
  height: 20px;
  background: #cb3b43;
  border-radius: 0 5px 5px 0;
}

.ta-menu__avatar {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.ta-menu__avatar .ta-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 7px;
}

.ta-menu__avatar .ta-avatar .ta-avatar__image {
  width: 20px;
  height: 20px;
}

.ta-menu__avatar .ta-avatar .ta-avatar__image img {
  width: 20px;
  height: 20px;
}

.ta-menu__notifications {
  position: absolute;
  width: 12px;
  height: 38px;
  top: 0;
  left: 100%;
}

.ta-menu__notifications__counter {
  width: 16px;
  height: 16px;
  text-align: center;
  color: #fff;
  background: #cb3b43;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
  border-radius: 16px;
  font-size: 8px;
  font-weight: 500;
  line-height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}