.ta-input__limit {
  position: relative;
  bottom: 7px;
  right: 7px;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  float: left;
}

.ta-input__right-margin {
  margin-right: 45px;
}

.ta-form-control__btn-clear {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
  background: #b6babd;
  color: #505a5e;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 100;
  border-radius: 4px;
  cursor: pointer;
}
