.ta-maximum-lead-time-example {
  margin-top: 5px;
}

.ta-maximum-lead-time-example__label {
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.8;
  margin: 4px 0;
  line-height: 15px;
  text-align: center;
  color: #505a5e;
}

.ta-maximum-lead-time-example__timeline {
  height: 60px;
  min-width: 418px;
}

.ta-maximum-lead-time-example__timeline-active {
  position: relative;
  border-bottom: 2px solid #505a5e;
  height: 30px;
  width: 90px;
  display: inline-block;
  transition: all .5s;
}

.ta-maximum-lead-time-example__timeline-active:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #505a5e;
  position: absolute;
  top: 25px;
  left: 0;
}

.ta-maximum-lead-time-example.months-null .ta-maximum-lead-time-example__timeline-active,
.ta-maximum-lead-time-example.months-NaN .ta-maximum-lead-time-example__timeline-active {
  width: 343px;
}

.ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__timeline-active {
  width: 113px;
}

.ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__timeline-active {
  width: 136px;
}

.ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__timeline-active {
  width: 159px;
}

.ta-maximum-lead-time-example.narrow.months-4 .ta-maximum-lead-time-example__timeline-active {
  width: 142px;
}

.ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__timeline-active {
  width: 182px;
}

.ta-maximum-lead-time-example.narrow.months-5 .ta-maximum-lead-time-example__timeline-active {
  width: 165px;
}

.ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__timeline-active {
  width: 205px;
}

.ta-maximum-lead-time-example.months-7 .ta-maximum-lead-time-example__timeline-active {
  width: 228px;
}

.ta-maximum-lead-time-example.narrow.months-7 .ta-maximum-lead-time-example__timeline-active {
  width: 211px;
}

.ta-maximum-lead-time-example.months-8 .ta-maximum-lead-time-example__timeline-active {
  width: 251px;
}

.ta-maximum-lead-time-example.narrow.months-8 .ta-maximum-lead-time-example__timeline-active {
  width: 234px;
}

.ta-maximum-lead-time-example.months-9 .ta-maximum-lead-time-example__timeline-active {
  width: 274px;
}

.ta-maximum-lead-time-example.narrow.months-9 .ta-maximum-lead-time-example__timeline-active {
  width: 257px;
}

.ta-maximum-lead-time-example.months-10 .ta-maximum-lead-time-example__timeline-active {
  width: 297px;
}

.ta-maximum-lead-time-example.narrow.months-10 .ta-maximum-lead-time-example__timeline-active {
  width: 280px;
}

.ta-maximum-lead-time-example.months-11 .ta-maximum-lead-time-example__timeline-active {
  width: 320px;
}

.ta-maximum-lead-time-example.narrow.months-11 .ta-maximum-lead-time-example__timeline-active {
  width: 303px;
}

.ta-maximum-lead-time-example.months-12 .ta-maximum-lead-time-example__timeline-active {
  width: 343px;
}

.ta-maximum-lead-time-example__timeline-inactive {
  position: relative;
  border-bottom: 2px dashed #c9c8c6;
  height: 30px;
  width: 69px;
  display: inline-block;
}

.ta-maximum-lead-time-example__timeline-inactive:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #fff;
  border: 2px solid #505a5e;
  position: absolute;
  top: 25px;
  left: 0;
}

.ta-maximum-lead-time-example__timeline-inactive:after {
  content: "";
  border: 5px solid #c9c8c6;
  border-color: transparent transparent transparent #c9c8c6;
  position: absolute;
  top: 24px;
  right: -8px;
}

.ta-maximum-lead-time-example__now {
  position: absolute;
  top: 49px;
  left: -15px;
  font-size: 8px;
  font-weight: 500;
  color: #505a5e;
  text-transform: uppercase;
  width: 40px;
  text-align: center;
  transform: rotate(-90deg);
}

.ta-maximum-lead-time-example__month {
  position: absolute;
  top: 9px;
  left: 35px;
  width: 30px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 5px 0;
  background: #fff;
  border-radius: 2px;
  opacity: 1;
  transition: all .5s;
  text-align: center;
  padding: 3px;
}

.ta-maximum-lead-time-example__month div {
  background: #c9c8c6;
  color: #505a5e;
  border-radius: 1px;
  padding: 2px;
  opacity: 0.3;
  font-size: 6px;
  font-weight: 500;
}

.ta-maximum-lead-time-example__month span {
  display: block;
  width: 14px;
  height: 3px;
  border-radius: 1px;
  background: #c9c8c6;
  opacity: 0.3;
  margin: 2px auto;
}

.ta-maximum-lead-time-example__month ul {
  margin-top: 3px;
}

.ta-maximum-lead-time-example__month ul li {
  width: 5px;
  height: 3px;
  border-radius: 2px;
  margin-right: 1px;
  margin-bottom: 1px;
  float: left;
  background: #505a5e;
  opacity: 0.5;
}

.ta-maximum-lead-time-example__month i {
  color: #cb3b43;
  margin-top: 1px;
}

.ta-maximum-lead-time-example__month.month-2 {
  left: 58px;
  transform: translateY(-5px) translateZ(0);
}

.ta-maximum-lead-time-example__month.month-3 {
  left: 81px;
}

.ta-maximum-lead-time-example__month.month-4 {
  left: 104px;
  transform: translateY(5px) translateZ(0);
}

.ta-maximum-lead-time-example__month.month-5 {
  left: 127px;
}

.ta-maximum-lead-time-example__month.month-6 {
  left: 150px;
  transform: translateY(-5px) translateZ(0);
}

.ta-maximum-lead-time-example__month.month-7 {
  left: 173px;
}

.ta-maximum-lead-time-example__month.month-8 {
  left: 196px;
  transform: translateY(5px) translateZ(0);
}

.ta-maximum-lead-time-example__month.month-9 {
  left: 219px;
}

.ta-maximum-lead-time-example__month.month-10 {
  left: 242px;
  transform: translateY(-5px) translateZ(0);
}

.ta-maximum-lead-time-example__month.month-11 {
  left: 265px;
}

.ta-maximum-lead-time-example__month.month-12 {
  left: 288px;
  transform: translateY(5px) translateZ(0);
}

.ta-maximum-lead-time-example__month.month-empty {
  left: 22px;
}

.ta-maximum-lead-time-example.months-11 .ta-maximum-lead-time-example__month.month-12 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-10 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-10 .ta-maximum-lead-time-example__month.month-11 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-9 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-9 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-9 .ta-maximum-lead-time-example__month.month-10 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-8 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-8 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-8 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-8 .ta-maximum-lead-time-example__month.month-9 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-7 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-7 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-7 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-7 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-7 .ta-maximum-lead-time-example__month.month-8 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-6 .ta-maximum-lead-time-example__month.month-7 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-7, .ta-maximum-lead-time-example.months-5 .ta-maximum-lead-time-example__month.month-6 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-7, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-6, .ta-maximum-lead-time-example.months-4 .ta-maximum-lead-time-example__month.month-5 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-7, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-6, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-5, .ta-maximum-lead-time-example.months-3 .ta-maximum-lead-time-example__month.month-4 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-7, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-6, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-5, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-4, .ta-maximum-lead-time-example.months-2 .ta-maximum-lead-time-example__month.month-3 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-7, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-6, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-5, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-4, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-3, .ta-maximum-lead-time-example.months-1 .ta-maximum-lead-time-example__month.month-2 {
  opacity: 0;
}

.ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-12, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-11, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-10, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-9, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-8, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-7, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-6, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-5, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-4, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-3, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-2, .ta-maximum-lead-time-example.months-0 .ta-maximum-lead-time-example__month.month-1 {
  opacity: 0;
}
