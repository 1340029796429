.ta-billing__form .ta-fixed-content__body {
  padding-top: 15px;
}

.ta-billing__preview .ta-fixed-content__body {
  padding-top: 15px;
  padding-bottom: 30px;
}

.ta-billing__title {
  padding: 20px 0 15px;
}

.ta-billing .ta-title__content .ta-btn,
.ta-billing__preview .ta-title__content .ta-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ta-billing__preview .ta-title__content .ta-btn:not(:first-of-type) {
  margin-left: 4px;
}

.ta-billing .ta-title__content .ta-title__link {
  font-size: 12px;
  font-weight: bold;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  margin-right: 8px;
  display: inline-block;
}

.ta-billing .ta-title__content .ta-title__link i {
  font-size: 14px;
  margin-left: 4px;
}

.ta-billing__preview .ta-title__content :not(:last-child) {
  margin-right: 4px;
}

.ta-billing__preview .payment-method.ta-simple-list-item.active,
.ta-billing__preview .payment-method.ta-simple-list-item {
  padding-left: 60px;
  border: solid 1px rgba(182, 186, 189, 0.5);
  min-height: 60px;
}

.ta-billing__preview__method-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%) translateZ(0);
  color: #505a5e;
  opacity: 0.5;
  font-size: 22px;
}

.ta-billing__preview__method-primary {
  background: #769851;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding: 0 5px;
  font-size: 10px;
  font-weight: 500;
}

.ta-billing__preview__method.deleted {
  position: relative;
}

.ta-billing__preview__method.deleted:before {
  content: "";
  background: rgba(244, 243, 243, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  animation-duration: 2s;
  animation-name: payment-method-deleted-before;
  animation-iteration-count: 1;
}

@keyframes payment-method-deleted-before {
  0% {
    background: rgba(244, 243, 243, 0);
  }

  15% {
    background: rgba(244, 243, 243, 0.7);
  }

  100% {
    background: rgba(244, 243, 243, 1);
  }
}

.ta-billing__preview__method.deleted:after {
  content: "\f1f8";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: 18px;
  color: #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  animation-duration: 2s;
  animation-name: payment-method-deleted-after;
  animation-iteration-count: 1;
}

@keyframes payment-method-deleted-after {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ta-billing__preview .ta-form {
  padding: 0;
}

.ta-billing__preview .ta-form .ta-form-error {
  margin-bottom: 10px;
}

.ta-billing__preview .ta-message {
  margin-top: 12px;

}

.ta-billing__preview .ta-message.active {

  margin-bottom: 0;
}

.ta-billing__preview__payment-methods__loader {
  position: relative;
  height: 100px;
}

.ta-billing__preview .ta-no-results {
  margin-bottom: 10px;
}

.ta-billing__preview .contract.ta-simple-list-item.active,
.ta-billing__preview .contract.ta-simple-list-item {
  border: solid 1px rgba(182, 186, 189, 0.5);
  min-height: 60px;
}

.ta-billing__preview .ta-simple-list-item.payment-method.paypal .ta-simple-list-item__title {
  margin-top: 10px;
}
