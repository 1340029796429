.ta-attributes__form .ta-bordered-box {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.ta-attributes__form__select-options .ta-title,
.ta-attributes__form__upload-settings .ta-title {
  line-height: 1.43;
  margin-bottom: 12px;
}