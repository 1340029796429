.ta-content {
  position: fixed;
  top: 0;
  left: 120px;
  right: 0;
  height: 100%;
  min-height: 700px;
  transform: translate3d(0, 0, 0);
  font-family: Ubuntu;
  background-color: #fafafa;
  padding: 24px;
  overflow-y: scroll;
  z-index: 3;
}

.ta-content.expand {
  left: 0;
}
