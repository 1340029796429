.ta-striped-title {
  line-height: 14px;
  position: relative;
  margin-bottom: 8px;
  opacity: 0.7;
  display: flex;
  align-items: center;
}

.ta-striped-title.top-offset {
  margin-top: 16px;
}

.ta-striped-title:after {
  content: "";
  border-bottom: 1px solid #505A5E;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  opacity: 0.25;
}

.ta-striped-title__label {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  color: #505A5E;
  text-transform: uppercase;
  padding: 0 4px 0 0;
  position: relative;
  z-index: 1;
  background-color: #f4f3f3;
}

.ta-bordered-box .ta-striped-title__label {
  background-color: #fff;
}
