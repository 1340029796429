.ta-error.ta-alert.ta-alert__top-offset {
  margin-top: 4px;
}

.ta-error__conflicts__box {
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  font-weight: 400;
  color: #1C272B;
}

.ta-error__conflicts__box__list-item {
  position: relative;
  margin-top: 4px;
  padding-left: 18px;
}

.ta-error__conflicts__box__list-item:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #1C272B;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
